
import { MessageTypeEnum, RecurringCampaignCooldownPeriodEnum, RecurringCampaignFrequencyEnum, RecurringCampaignStatusEnum } from '@shared/enums';
import { ICreateRecurringCampaignsRequest, IHttpResponse, IRecurringCampaign, IUpdateRecurringCampaignsRequest } from '@shared/models';
import { getCampaignEndDateForStartDate, getCampaignStartDate, getLocalDate, getOxfordCommaString } from '@shared/services';
import { Button, ButtonVariantEnum, ProgressBar } from '@Wonder-Cave/ui';
import { AxiosResponse } from 'axios';
import { addDays } from 'date-fns';
import { Formik, FormikProps } from 'formik';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { axiosGet, axiosPost, axiosPut } from '../../authAxios';
import { useClientContext } from '../../contexts/ClientContext';
import { NotificationType, useNotifications } from '../../contexts/NotificationContext';
import { useCustomDataPoints } from '../../hooks/useCustomDataPoints';
import useDomains from '../../hooks/useDomains';
import { formatBackendMongoDBQuery } from '../../providers/mongodb.provider';
import LoadingIndicator from '../shared/LoadingIndicator';
import { CampaignTypeEnum } from '../shared/recurringcampaigns/campaign-type-enum';
import Build from './Steps/Build/Build';
import { recurringCampaignBuildFormSchema } from './Steps/Build/types';
import Plan from './Steps/Plan';
import Preview from './Steps/Preview/Preview';
import { recurringCampaignPreviewFormSchema } from './Steps/Preview/types';
import { IRecurringCampaignForm, recurringCampaignPlanFormSchema } from './types';

const RecurringCampaignsCreate = () => {
  const { id } = useParams<any>();
  const navigate = useNavigate();
  const [saveExitLoading, setSaveExitLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [stepNumber, setStepNumber] = useState(0);
  const [nextLoading, setNextLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [leads, setLeads] = useState(0);
  const { addNotification } = useNotifications();
  const [submissionError, setSubmissionError] = useState<string>();
  const startsAt = getCampaignStartDate();
  const { selectedClientId } = useClientContext();

  const [{ data: domainsData, loading: domainsLoading, error: domainsError }] = useDomains();
  const domains = domainsData?.records ?? [];

  const [{ data: customDataPointsData, loading: customDataPointsLoading }] = useCustomDataPoints({ personalized: true, skip: 0, take: 1000, isActive: true });
  const customDataPoints = customDataPointsData?.records ?? [];

  const [formData, setFormData] = useState<IRecurringCampaignForm>({
    // Misc.
    status: RecurringCampaignStatusEnum.DRAFT,
    sendTestMessages: false,
    campaignType: CampaignTypeEnum.FUNDRAISING,
    frequencyCount: 1,

    // Plan
    isMMSVideo: false,
    mediaUrl: '',
    showSuppressions: false,
    name: '',
    messageType: MessageTypeEnum.SMS,
    externalId: '',
    mediaFile: '',
    mediaName: '',
    audiences: [],
    suppressions: [],
    frontendInlineSuppression: { rules: [], disabled: false },

    // Build
    url: '',
    domainId: '',
    clickTrackingEnabled: false,
    hasNoEndDate: true,
    message: '',
    startsAt: new Date(),
    endsAt: addDays(new Date(), 1),
    sendingStartsAt: startsAt,
    sendingEndsAt: getCampaignEndDateForStartDate(getLocalDate(), startsAt),
    frequency: RecurringCampaignFrequencyEnum.DAILY,
    cooldownPeriodCount: 1,
    cooldownPeriod: RecurringCampaignCooldownPeriodEnum.MONTHS
  });
  const submitPreview = async (formikProps) => {
    try {
      setLoading(true);
      let body = formikProps.values;
      body.clientId = selectedClientId;
      body.audienceIds = formikProps.values.audiences.map(a => a.value);
      body.suppressionIds = formikProps.values.suppressions.map(s => s.value);
      body.status = RecurringCampaignStatusEnum.ACTIVE;
      let response: AxiosResponse<IHttpResponse<object>>;
      if (id) {
        response = await axiosPut(`/recurring-campaigns/${id}`, body);

      } else {
        response = await axiosPost(`/recurring-campaigns/create`, body);
      }
      if (response?.status === 200) {
        addNotification({ header: 'Recurring Campaign saved successfully.', type: NotificationType.SUCCESS });
        navigate('/recurring-campaigns');
      }
      else {
        addNotification({ header: 'Recurring Campaign failed to save.', type: NotificationType.FAILURE });
      }

    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const saveDraft = async (formikProps, exit: boolean) => {
    exit ? setSaveExitLoading(true) : setSaveLoading(true);
    try {
      const touched = {};
      console.log(formikProps);
      Object.keys(formikProps.errors).forEach((k) => (touched[k] = true));
      formikProps.setTouched(touched, false);
      if (Object.keys(await formikProps.validateForm()).length <= 0) {
        if (!id) {
          const request: ICreateRecurringCampaignsRequest = {
            ...formikProps.values,
            clientId: selectedClientId,
            hasNoEndDate: !formikProps.values.hasNoEndDate,
            status: RecurringCampaignStatusEnum.DRAFT,
            audienceIds: formikProps.values.audiences.map(a => a.value),
            suppressionIds: formikProps.values.suppressions.map(s => s.value),
            inlineSuppressionFrontendFilter: formikProps?.values?.frontendInlineSuppression ?? null,
            inlineSuppressionBackendFilter: formikProps?.values?.frontendInlineSuppression?.rules?.length >= 1
              ? formatBackendMongoDBQuery(formikProps?.values?.frontendInlineSuppression, customDataPoints ?? [])
              : null
          };
          const response = await axiosPost(`/recurring-campaigns/create`, request);
          if (response.status === 200 && response?.data) {
            addNotification({ header: 'Draft campaign saved successfully', type: NotificationType.SUCCESS });
            if (exit) {
              navigate('/recurring-campaigns');
            } else {
              setFormData({ ...formikProps.values, id: response?.data?.recurringCampaign?.id ?? id ?? formikProps.values.id });
              return response;
            }
          }
        } else {
          const request: IUpdateRecurringCampaignsRequest = {
            ...formikProps.values,
            clientId: formikProps.values.client.value,
            hasNoEndDate: !formikProps.values.hasNoEndDate,
            status: RecurringCampaignStatusEnum.DRAFT,
            audienceIds: formikProps.values.audiences.map(a => a.value),
            suppressionIds: formikProps.values.suppressions.map(s => s.value),
            inlineSuppressionFrontendFilter: formikProps?.values?.frontendInlineSuppression ?? null,
            inlineSuppressionBackendFilter: formikProps?.values?.frontendInlineSuppression?.rules?.length >= 1
              ? formatBackendMongoDBQuery(formikProps?.values?.frontendInlineSuppression, customDataPoints ?? [])
              : null
          };
          const response = await axiosPut(`/recurring-campaigns/${id}`, request);
          if (response.status === 200 && response?.data) {
            addNotification({ header: 'Draft campaign saved successfully', type: NotificationType.SUCCESS });
            if (exit) {
              navigate('/recurring-campaigns');
            } else {
              setFormData({ ...formikProps.values, id: response?.data?.recurringCampaign?.id ?? id ?? formikProps.values.id });
              return response;
            }
          }
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      exit ? setSaveExitLoading(false) : setSaveLoading(false);
    }
  };



  function getFormattedDate(date: Date) {
    const year = date.getFullYear();
    const month = (1 + date.getMonth()).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return month + '/' + day + '/' + year;
  }

  const getScheduleMessage = (props: FormikProps<IRecurringCampaignForm>) => {
    const audiences = props?.values?.audiences ?? [];
    const sendingStartsAt = props?.values?.sendingStartsAt;
    const startsAt = props?.values?.startsAt;
    const endsAt = props?.values?.hasNoEndDate ? props?.values?.endsAt : undefined;

    if (isEmpty(audiences) || !sendingStartsAt || !startsAt) {
      return '';
    }

    const audienceNames = audiences?.map(audience => audience?.label) ?? [];
    const audienceNamesMessage = getOxfordCommaString(audienceNames);

    const startDate = getFormattedDate(startsAt);
    const time = sendingStartsAt.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric'
    });

    if (endsAt) {
      const endDate = getFormattedDate(endsAt);
      return `Your messages are scheduled to send to ${audienceNamesMessage} at ${time} everyday, from ${startDate} to ${endDate}.`;
    } else {
      return `Your messages are scheduled to send to ${audienceNamesMessage} at ${time} everyday, from ${startDate}.`;
    }
  };

  const submitPlan = async (formikProps) => {
    // Touch all required fields
    let errors = await formikProps.setTouched({
      isMMSVideo: true,
      mediaUrl: true,
      showSuppressions: true,
      name: true,
      messageType: true,
      externalId: true,
      mediaFile: true,
      mediaName: true,
      audiences: true,
      suppressions: true,
      frontendInlineSuppression: true,
    });
    if (Object.keys(errors).length <= 0) {
      setFormData(formikProps.values);
      setStepNumber((prevState) => prevState + 1);
    }
  };

  const submitBuild = async (formikProps) => {
    setNextLoading(true);
    try {
      const touched = {};
      Object.keys(formikProps.errors).forEach((k) => (touched[k] = true));
      formikProps.setTouched(touched);
      let errors = await formikProps.setTouched({
        url: true,
        domainId: true,
        clickTrackingEnabled: true,
        hasNoEndDate: true,
        message: true,
        startsAt: true,
        endsAt: true,
        sendingStartsAt: true,
        frequency: true,
      });
      if (Object.keys(errors).length <= 0) {
        setFormData({ ...formikProps.values });
        setStepNumber((prevState) => prevState + 1);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setNextLoading(false);
    }
  };

  const getRecurringCampaign = async (campaignId: string) => {
    const response = await axiosGet(`/recurring-campaigns/${campaignId}`);
    const campaign: IRecurringCampaign = response?.data;
    const formattedStartDate = new Date(campaign.startsAt);
    const startsAt = formattedStartDate < new Date() ? getCampaignStartDate() : formattedStartDate;
    const endsAt = new Date(campaign.endsAt);
    setFormData({
      ...campaign,
      name: campaign?.name,
      client: { label: ' ', value: campaign.clientId },
      campaignType: CampaignTypeEnum.FUNDRAISING,
      showSuppressions: campaign?.frontendInlineSuppression?.rules?.length > 0,
      audiences: campaign?.audiences.map(a => ({ label: a.name, value: a.id })),
      suppressions: campaign?.suppressions.map(s => ({ label: s.name, value: s.id })),
      startsAt: startsAt,
      endsAt: endsAt,
      domainId: campaign?.domain,
      sendingStartsAt: startsAt,
      sendingEndsAt: endsAt,
      hasNoEndDate: !campaign?.hasNoEndDate,
      // MMS set a dummy file for validation
      mediaFile: campaign.messageType === MessageTypeEnum.MMS ? new File([''], 'filename.png', { type: 'image' }) : undefined,
      cooldownPeriodCount: campaign.cooldownPeriodCount,
      cooldownPeriod: campaign.cooldownPeriod
    });
  };

  useEffect(() => {
    if (id) {
      getRecurringCampaign(id);
    }
  }, [id]);

  const stepConfigs = [
    {
      submit: submitPlan,
      schema: recurringCampaignPlanFormSchema,
    },
    {
      submit: submitBuild,
      schema: recurringCampaignBuildFormSchema,
    },
    {
      submit: submitPreview,
      schema: recurringCampaignPreviewFormSchema, // No schema for this one, nothing to validate
    },
  ];

  const Step = ({ stepNumber, ...rest }) => {
    switch (stepNumber) {
      case 0:
        return <Plan values={formData} onSubmit={stepConfigs[stepNumber].submit} leads={leads} setLeads={setLeads} setLoading={setLoading} {...rest} submissionError={submissionError} />;
      case 1:
        return <Build values={formData} onSubmit={stepConfigs[stepNumber].submit} leads={leads} setLeads={setLeads} setLoading={setLoading} {...rest} submissionError={submissionError} domains={domains} customDataPoints={customDataPoints} />;
      case 2:
        return <Preview values={formData} leads={leads} setLeads={setLeads} submissionError={submissionError} saveLoading={saveLoading} saveDraft={saveDraft} setLoading={setLoading} sending={sending} setSending={setSending} {...rest} customDataPoints={customDataPoints} />;
      default:
        return <Plan values={formData} onSubmit={stepConfigs[stepNumber].submit} {...rest} leads={0} setLeads={() => { }} setLoading={setLoading} submissionError={submissionError} />;
    }
  };
  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between w-full mb-8 px-28">
        <h1>{formData?.name && stepNumber === 2 ? formData?.name : 'Create Recurring Campaign'}</h1>
        <Button isLoading={saveExitLoading} disabled={nextLoading || sending} type="submit" formId="campaign-form" variant={ButtonVariantEnum.TERTIARY}>
          SAVE DRAFT & EXIT
        </Button>
      </div>
      <ProgressBar
        className="-ml-4 w-[calc(100%+2rem)]"
        steps={[
          { num: 1, description: 'Plan', active: stepNumber === 0 },
          { num: 2, description: 'Build', active: stepNumber === 1 },
          { num: 3, description: 'Preview', active: stepNumber === 2 },
        ]}
      />
      <div className="relative mt-8 px-28">
        {loading && (
          <div
            className="flex absolute z-20 w-[calc(100%+2rem)] h-[calc(100%+2rem)] -ml-32 -mt-8 backdrop-blur-[1.5px]"
            aria-hidden="true"
          >
            <div className="mx-auto mt-1/5">
              <LoadingIndicator size={6} />
            </div>
          </div>
        )}
        <Formik
          initialValues={formData}
          validationSchema={stepConfigs[stepNumber].schema}
          onSubmit={stepConfigs[stepNumber].submit}
          enableReinitialize
          initialStatus={true}
        >
          {(props) => {
            return (
              <form
                id="campaign-form"
                onSubmit={(event) => {
                  event.preventDefault();
                  saveDraft(props, true);
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                  }
                }}
              >
                <Step stepNumber={stepNumber} {...props} />

                <div className='flex flex-row justify-end w-full pb-2 mt-auto'>
                  {stepNumber === 1 && (
                    <h3 className='mr-[4rem] w-full text-wc-blue'>
                      {getScheduleMessage(props)}
                    </h3>
                  )}

                  <Button
                    variant={ButtonVariantEnum.SECONDARY}
                    className="mr-4"
                    type="button"
                    onClick={() => (stepNumber === 0 ? history.back() : setStepNumber((prevState) => prevState - 1))}
                  >
                    BACK
                  </Button>
                  <Button type="button" isLoading={nextLoading} onClick={() => stepConfigs[stepNumber].submit(props)} disabled={loading || saveExitLoading || sending}>
                    {stepNumber === 2 ? 'SAVE & EXECUTE' : 'NEXT'}
                  </Button>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default RecurringCampaignsCreate;
