import { IGetAllRecurringCampaignsRequest, IHttpPaginatedResponse, IRecurringCampaignOverview, ISearchRequest } from '@shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';
import { getFilterValue } from '../authAxios';

export default function useRecurringCampaigns(request: ISearchRequest): UseAxiosResult<IHttpPaginatedResponse<IRecurringCampaignOverview>> {
  const clientId = request?.clientId ?? getFilterValue(request, 'clientName');

  const params: IGetAllRecurringCampaignsRequest = {
    clientId,
    skip: request?.pagination?.skip ?? 0,
    take: request?.pagination?.take ?? 25,
    name: getFilterValue(request, 'name')
  };

  return useAxios<IHttpPaginatedResponse<IRecurringCampaignOverview>>({
    url: 'recurring-campaigns',
    method: 'GET',
    params
  });
}
