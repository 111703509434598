
import { ActionWithRulesAndAddersProps, RuleOrGroupArray } from 'react-querybuilder';
import { Link } from 'react-router-dom';


export interface ICustomAddRuleButtonProps extends ActionWithRulesAndAddersProps {
  maxRules?: number;
  rules?: RuleOrGroupArray;
  disabled?: boolean;
  handleOnClick(e: any, context?: any): void;
}

export const CustomAddRuleButton = (props: ICustomAddRuleButtonProps) => {
  return (
    <div className='pr-5 text-blue-500 bg-gray-100 font-extrabold hover:bg-gray-100 shadow-none text-md mr-3 underline underline-offset-4 decoration-2'>
      <Link
        style={(((props.rules?.length ?? 0) >= (props.maxRules ?? 9)) || props.disabled) ? { pointerEvents: "none" } : undefined}
        onClick={props.handleOnClick}
        to=""
        target="_blank"
      >
        Add Rule +
      </Link>
    </div>
  );
};
