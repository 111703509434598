import { ClockIcon } from '@heroicons/react/outline';
import { RecurringCampaignStatusEnum } from '@shared/enums';
import { FilterDataTypeEnum, FilterOperatorEnum, IRecurringCampaignOverview, ISearchFilter, ISearchRequest } from '@shared/models';
import { capitalize } from '@shared/services';
import { Button, TableList } from '@Wonder-Cave/ui';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { axiosPatch } from '../../authAxios';
import { useClientContext } from '../../contexts/ClientContext';
import { NotificationType, useNotifications } from '../../contexts/NotificationContext';
import useRecurringCampaigns from '../../hooks/useRecurringCampaigns';
import NewTextInput from '../shared/Form/NewTextInput';
import RecurringCampaignDeleteModal from './RecurringCampaignDeleteModal';
import RecurringCampaignPauseResumeModal from './RecurringCampaignPauseResumeModal';
import { defaultRecurringCampaignsTableOptions, getColumns } from './types';

const RecurringCampaigns = () => {
  // const { addNotification } = useNotifications();
  // const [showDetailsPanel, setShowDetailsPanel] = useState(false);
  // const [campaignCreating, setCampaignCreating] = useState(false);
  // const [errorMessage, setErrorMessage] = useState('');
  // const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IRecurringCampaignOverview>();
  const [searchText, setSearchText] = useState('');
  const { selectedClientId } = useClientContext();
  const [showResumePauseModal, setShowResumePauseModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [pauseLoading, setPauseLoading] = useState(false);
  const [isCampaignActive, setIsCampaignActive] = useState(false);
  const [isCampaignPauseLoading, setIsCampaignPauseLoading] = useState(false);
  const { addNotification } = useNotifications();
  const [tableOptions, setTableOptions] = useState({
    ...defaultRecurringCampaignsTableOptions,
  } as ISearchRequest);
  const [{ data: campaignData, loading: campaignLoading }, refetch] = useRecurringCampaigns({
    ...tableOptions,
    clientId: selectedClientId,
  });

  const navigate = useNavigate();

  useEffect(() => {
    const searchText = tableOptions.filters.find((filter) => filter.fieldName === 'name')?.value;

    handleRefetch();

    setSearchText(searchText ?? '');
  }, [tableOptions]);

  const goToCreateCampaign = () => {
    navigate('/recurring-campaigns/create');
  };

  const columns = getColumns(
    {
      name: (item: IRecurringCampaignOverview) => {

        if (item.status === RecurringCampaignStatusEnum.DRAFT) {
          navigate(`/recurring-campaigns/${item.id}/edit`);
        } else {
          navigate(`/recurring-campaigns/${item.id}`);
        }

      },
    },
    {
      name: (item: IRecurringCampaignOverview) => {
        if (!item) {
          return;
        }

        const sendTimeParts = new Date(item.sendingStartsAt).toLocaleTimeString().split(' ');
        const sendTimeClockStr = sendTimeParts[0].split(':').slice(0, -1).join(':');
        return <>
          <div className='mb-2 text-lg text-sky-600 hover:text-sky-900 hover:underline'>{item.name}</div>
          <div className='flex flex-row items-center text-xs text-medium-gray fill-medium-gray'>
            <ClockIcon width={15} className='mr-2' />
            <span>{capitalize(item.frequency.toLowerCase())} at {sendTimeClockStr} {sendTimeParts[1]}</span>
          </div>
        </>;
      },
      duration: (item: IRecurringCampaignOverview) => item && `${new Date(item.startsAt).toLocaleDateString()} - ${new Date(item.endsAt).toLocaleDateString()}`
    }
  );

  const handleRefetch = async () => {
    try {
      await refetch();
    } catch (error) { }
  };

  const handleRecurringCampaignPause = async () => {
    try {
      setPauseLoading(true);
      await axiosPatch(`/recurring-campaigns/${selectedItem?.id}`, {
        status: isCampaignActive ? RecurringCampaignStatusEnum.PAUSED : RecurringCampaignStatusEnum.ACTIVE
      });
      //await getRecurringCampaign(id ?? '');

      addNotification({
        header: !isCampaignActive ? 'Campaign resumed successfully' : 'Campaign paused successfully',
      });

    } catch (err) {
      console.error(err);
    } finally {
      setPauseLoading(false);
    }
    setPauseLoading(false);
    await refetch();
  };

  const handleRecurringCampaignDelete = async () => {
    setPauseLoading(true);
    try {
      await axiosPatch(`/recurring-campaigns/${selectedItem?.id}/delete`, {});
      addNotification({
        header: 'Recurring Campaign Deleted'
      });
      navigate('/recurring-campaigns/');
    } catch (err) {
      addNotification({
        header: 'Recurring Campaign Deleted Failed',
        type: NotificationType.FAILURE
      });
    }
    finally {
      setPauseLoading(false);
      await refetch();
    }
  };

  const handleClear = () => {
    const newSearchOptions = { ...tableOptions };
    newSearchOptions.filters = newSearchOptions.filters.filter((filter) => filter.fieldName !== 'name');
    setTableOptions(newSearchOptions);
  };

  const checkForClear = () => {
    const sanitizedSearchText = searchText.replace(/[()-\s]/g, '');

    const currSearchText = tableOptions.filters.find((filter) => filter.fieldName === 'name')?.value;

    if (currSearchText && !sanitizedSearchText) {
      handleClear();
    }
  };

  const handleSearch = () => {
    const sanitizedSearchText = searchText.trim();

    const currSearchText = tableOptions.filters.find((filter) => filter.fieldName === 'name')?.value;

    // Check for no-change
    if (currSearchText === sanitizedSearchText) {
      return;
    }

    // Check for clear
    if (currSearchText && !sanitizedSearchText) {
      return handleClear();
    }

    let searchFilter: ISearchFilter = {
      dataType: FilterDataTypeEnum.STRING,
      fieldName: 'name',
      operator: FilterOperatorEnum.CONTAINS,
      value: sanitizedSearchText,
    };

    const newSearchOptions = { ...tableOptions };
    newSearchOptions.filters = newSearchOptions.filters.filter((filter) => filter.fieldName !== 'name');
    newSearchOptions.pagination = defaultRecurringCampaignsTableOptions.pagination;
    newSearchOptions.filters.push(searchFilter);

    setTableOptions(newSearchOptions);
  };

  return (
    <>
      <div className="flex items-center justify-between pb-2 mx-auto px-[7.5rem] min-w-[1151px]">
        <h1 className="header-page">Recurring Campaigns </h1>
        <div className='flex'>
          <div className="flex mr-2">
            <NewTextInput
              onEnter={handleSearch}
              onBlur={checkForClear}
              onClear={handleClear}
              className='w-80'
              name="search"
              search
              placeholder='Search'
              onChange={(e) => setSearchText(e.target.value)}
              trailingIconClassName='mr-0'
              value={searchText}
              clearable
            />
          </div>
          <Button className='self-end ml-4' onClick={() => goToCreateCampaign()}>Create Recurring Campaign</Button>
        </div>
      </div>
      {campaignData && (
        <div className='h-5/6 w-full min-w-[1151px]'>
          <TableList
            columns={columns}
            items={campaignData?.records}
            totalCount={campaignData?.totalCount ?? 0}
            loading={campaignLoading}
            limit={25}
            skipEnumFormattingColumns={['messageType']}
            shimmer
            paginate
            onPaginate={(page) => {
              setTableOptions((prevState) => ({
                ...prevState,
                pagination: {
                  ...prevState.pagination,
                  skip: page * prevState.pagination.take,
                },
              }));
            }}
            actions={[
              {
                label: 'Pause',
                action: async (item) => {
                  setSelectedItem(item);
                  setIsCampaignActive([RecurringCampaignStatusEnum.ACTIVE, RecurringCampaignStatusEnum.SCHEDULING].includes(
                    item?.status ?? RecurringCampaignStatusEnum.COMPLETE
                  ));
                  setShowResumePauseModal(true);
                },
                hidden: (item) => {
                  return ![RecurringCampaignStatusEnum.ACTIVE, RecurringCampaignStatusEnum.SCHEDULING].includes(item.status);
                },
              },
              {
                label: 'Resume',
                action: async (item) => {
                  setSelectedItem(item);
                  setIsCampaignActive([RecurringCampaignStatusEnum.ACTIVE, RecurringCampaignStatusEnum.SCHEDULING].includes(
                    item?.status ?? RecurringCampaignStatusEnum.COMPLETE
                  ));
                  setShowResumePauseModal(true);
                },
                hidden: (item) => item.status !== RecurringCampaignStatusEnum.PAUSED,
              },
              {
                label: 'Delete',
                action: async (item) => {
                  setSelectedItem(item);
                  setShowDeleteModal(true);
                },
                hidden: (item) => {
                  return (
                    ![RecurringCampaignStatusEnum.PAUSED, RecurringCampaignStatusEnum.DRAFT].includes(item.status)
                  );
                },
              },
            ]}
          />
        </div>
      )}



      <RecurringCampaignDeleteModal
        isLoading={isLoading}
        showModal={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
        }}
        onSuccess={async () => {
          setIsLoading(true);
          await handleRecurringCampaignDelete();
          setIsLoading(false);
          setShowDeleteModal(false);
        }}
        onCancel={() => {
          setShowDeleteModal(false);
        }}
      />

      <RecurringCampaignPauseResumeModal
        isLoading={isLoading}
        isCampaignActive={isCampaignActive}
        showModal={showResumePauseModal}
        onClose={() => {
          setShowResumePauseModal(false);
        }}
        onSuccess={async () => {
          setIsLoading(true);
          await handleRecurringCampaignPause();
          setIsLoading(false);
          setShowResumePauseModal(false);
          // await refetchCampaigns();
        }}
        onCancel={() => {
          setShowResumePauseModal(false);
        }}
      />
    </>
  );
};

export default RecurringCampaigns;
