import { FieldTypeEnum } from '@shared/enums';
import { Field, QueryBuilder, RuleGroupTypeIC } from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.scss';
import { CustomAddGroupButton } from './CustomAddGroupButton';
import { CustomAddRuleButton } from './CustomAddRuleButton';
import { CustomCombinatorSelector } from './CustomCombinatorSelector';
import { CustomFieldSelector } from './CustomFieldSelector';
import { CustomOperatorSelector } from './CustomOperatorSelector';
import { CustomRemoveButton } from './CustomRemoveButton';
import { CustomValueEditor } from './CustomValueEditor';
import './style.scss';
import { fieldTypeOperatorMap } from './types';

export interface INewQueryBuilderProps {
  fields?: Field[];
  query: RuleGroupTypeIC | undefined;
  setQuery: (query: RuleGroupTypeIC) => void;
  hideAddQuery?: boolean;
  maxRules?: number;
}

export default ({ fields, query, setQuery, maxRules, hideAddQuery }: INewQueryBuilderProps) => {
  return (
    <QueryBuilder
      fields={fields}
      query={query}
      onQueryChange={(q) => setQuery(q)}
      independentCombinators
      showCombinatorsBetweenRules
      getOperators={(field) => {
        const match = fields?.find((f) => f.name === field);
        const fieldType = match?.inputType as FieldTypeEnum ?? FieldTypeEnum.STRING;
        return fieldTypeOperatorMap?.get(fieldType) ?? [];
      }}
      controlElements={{
        fieldSelector: CustomFieldSelector,
        operatorSelector: CustomOperatorSelector,
        combinatorSelector: CustomCombinatorSelector,
        valueEditor: CustomValueEditor,
        removeGroupAction: CustomRemoveButton,
        removeRuleAction: CustomRemoveButton,
        addGroupAction: (props) => (hideAddQuery ? null : <CustomAddGroupButton query={query!} {...props} />),
        addRuleAction: (props) => <CustomAddRuleButton maxRules={maxRules} {...props} />
      }}
    />
  );
};
