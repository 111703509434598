import { FieldTypeEnum } from '@shared/enums';
import { Autocomplete, DateTimePicker, TextInput } from '@Wonder-Cave/ui';
import { ValueEditorProps } from 'react-querybuilder';

import { IDropdownValue } from '../Dropdown';
import { QueryBuilderOperator } from './types';

export const CustomValueEditor = (props: ValueEditorProps) => {
  const type = props.inputType as FieldTypeEnum;
  const operator = props.operator as QueryBuilderOperator;

  switch (type) {
    case FieldTypeEnum.STRING:
    case FieldTypeEnum.NUMBER:
      return operator === 'between'
        ? getBetweenInput(props)
        : getTextInput(props);

    case FieldTypeEnum.DATE:
      switch (operator) {
        case 'between':
          return getDateBetweenInput(props);
        case 'gteDaysAgo':
        case 'lteDaysAgo':
        case 'gteDaysFromNow':
        case 'lteDaysFromNow':
          return getRelativeDateInput(props);
        default:
          return getDateInput(props);
      }

    case FieldTypeEnum.BOOLEAN:
      return getBooleanInput(props);

    default:
      return <></>;
  }
};

function getRelativeDateInput(props: ValueEditorProps) {
  return <div className='flex w-1/3'><TextInput
    name={'Days'}
    type={'number'}
    value={props.value}
    onChange={(ev) => props.handleOnChange(ev.target.value)}
    disabled={props.disabled} /></div>;
}

function getTextInput(props: ValueEditorProps) {
  return <div className='flex w-1/3'> <TextInput
    name={props.field}
    type={props.inputType === FieldTypeEnum.NUMBER ? 'number' : ''}
    value={props.value}
    onChange={(ev) => props.handleOnChange(ev.target.value)}
    disabled={props.disabled} /></div>;
}

function getBooleanInput(props: ValueEditorProps) {
  const options = [
    {
      label: 'True',
      value: true,
    },
    {
      label: 'False',
      value: false,
    },
  ] as IDropdownValue[];

  return <Autocomplete
    onChange={(ev) => props.handleOnChange(ev?.value)}
    value={options.find((d) => d.value === props.value)}
    options={options}
    disabled={props.disabled} />;
}

function getDateInput(props: ValueEditorProps) {
  return <DateTimePicker value={new Date(props.value)} onChange={(ev) => props.handleOnChange(ev)} disabled={props.disabled} />;
}

function getDateBetweenInput(props: ValueEditorProps) {
  return <div className='flex space-x-2'>
    <DateTimePicker value={new Date(props.value[0])} onChange={(ev) => props.handleOnChange([ev, props.value[1]])} disabled={props.disabled} />
    <DateTimePicker value={new Date(props.value[1])} onChange={(ev) => props.handleOnChange([props.value[0], ev])} disabled={props.disabled} />
  </div>;
}

function getBetweenInput(props: ValueEditorProps) {
  return <div className='flex space-x-2'>
    <TextInput
      name={props.field}
      type={props.inputType === FieldTypeEnum.NUMBER ? 'number' : ''}
      value={props.value[0]}
      onChange={(ev) => props.handleOnChange([ev.target.value, props.value[1]])}
      disabled={props.disabled} />
    <TextInput
      name={props.field}
      value={props.value[1]}
      type={props.inputType === FieldTypeEnum.NUMBER ? 'number' : ''}
      onChange={(ev) => props.handleOnChange([props.value[0], ev.target.value])}
      disabled={props.disabled} />
  </div>;
}
