import { Autocomplete } from '@Wonder-Cave/ui';
import { Option, ValueSelectorProps } from 'react-querybuilder';
// and or dropdown

export const CustomCombinatorSelector = (props: ValueSelectorProps) => {
  const selectedOption = (props.options as Option[]).find((o) => o.name === props.value);
  return (
    <div className='flex w-1/6'>
      <Autocomplete
        label=""
        value={{
          value: selectedOption?.name,
          label: selectedOption?.label ?? '',
        }}
        options={(props.options as Option[]).map((o) => {
          return {
            value: o.name,
            label: o.label,
          };
        })}
        loading={false}
        onChange={(selectedOption) => props.handleOnChange(selectedOption?.value)}
        disabled={props.disabled}
        multiple={false} />
    </div>
  );
};
