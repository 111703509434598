import { ISuppressionEntity } from '@shared/models';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import { DetailsPanel } from '../shared/DetailsPanel/DetailsPanel';
import { DetailsPanelSize, IButtonOptions } from '../shared/DetailsPanel/types';
import { SuppressionForm } from './SuppressionForm';
import { ISuppressionForm } from './types';

interface SuppressionDetailsPanel {
  show: boolean;
  loading: boolean;
  deleteLoading: boolean;
  selectedItem: ISuppressionEntity | undefined;
  onClosePanel: () => void;
  handleSubmit: (formData: ISuppressionForm) => void;
  handleDelete?: () => Promise<void>;
}

export const SuppressionDetailPanel = ({
  show,
  loading,
  deleteLoading,
  selectedItem,
  onClosePanel,
  handleSubmit,
  handleDelete,
}: SuppressionDetailsPanel) => {
  const leftPanelButtonOptions: IButtonOptions[] = [
    {
      text: 'Delete',
      onClick: handleDelete,
      visible: !!selectedItem?.id && !selectedItem?.readOnly,
      variant: ButtonVariantEnum.DELETE,
      loading: deleteLoading,
    },
  ];

  return (
    <DetailsPanel
      item={selectedItem}
      size={DetailsPanelSize.Large}
      show={show}
      loading={loading}
      formId="suppression-form"
      closePanel={onClosePanel}
      title={!!selectedItem ? 'Edit Suppression' : 'Add Suppression'}
      leftPanelButtonOptions={leftPanelButtonOptions}
    >
      <SuppressionForm selectedItem={selectedItem} handleSubmit={handleSubmit} />
    </DetailsPanel>
  );
};
