import { ClockIcon } from '@heroicons/react/outline';
import { MessageTypeEnum, ProviderName, RecurringCampaignStatusEnum } from '@shared/enums';
import { ICampaignEntity, IHttpResponse, IRecurringCampaign, IRecurringCampaignInstanceOverview, IRecurringCampaignMetricsClickRate, IRecurringCampaignMetricsDeliveredRate, IRecurringCampaignMetricsOptoutRate, ISearchRequest, UpdateCampaignRequest } from '@shared/models';
import { getOxfordCommaString } from '@shared/services';
import { Button, ButtonVariantEnum, Chip, GlacierCheckbox, Info, Shimmer, ShimmerType, Static } from '@Wonder-Cave/ui';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { axiosGet, axiosPatch, axiosPost } from '../../authAxios';
import { NotificationType, useNotifications } from '../../contexts/NotificationContext';
import { useCustomDataPoints } from '../../hooks/useCustomDataPoints';
import useRecurringCampaignCampaigns from '../../hooks/useRecurringCampaignCampaigns';
import CampaignDeliveredProgressBar from '../Campaigns/CampaignDeliveredProgressBar';
import CampaignPauseResumeModal from '../Campaigns/CampaignPauseResumeModal';
import MessagePreview from '../shared/Form/MessagePreview';
import { Table } from '../shared/Table/Table';
import RecurringCampaignDeleteModal from './RecurringCampaignDeleteModal';
import RecurringCampaignPauseResumeModal from './RecurringCampaignPauseResumeModal';
import { defaultRecurringCampaignCampaignsTableOptions, getCampaignColumns } from './types';

interface IMetricCardProps {
  title: string;
  metric: number | null | undefined;
  loading: boolean;
  footer?: React.ReactNode;
  _export?: boolean;
  onExport?: () => void;
  exportLoading?: boolean;
  flat?: boolean;
  disabled?: boolean;
}

const MetricCard = ({ title, loading, metric, footer, _export, onExport, flat, exportLoading, disabled = false }: IMetricCardProps) => {
  let metricText = metric !== null && metric !== undefined
    ? `${isFinite(metric!) ? (flat ? metric : metric!.toFixed(1)) : 0}${flat ? '' : '%'}`
    : '-';

  return (
    <div
      className={`${flat ? '' : 'rounded-2.5xl'} bg-white flex flex-col items-center p-4 space-y-4 ${flat ? '' : 'w-44'
        }`}
      style={{ boxShadow: flat ? '' : '0px 0px 70px 0px #E9EBF0' }}
    >
      <h5 className="text-medium-gray">{title}</h5>
      {loading ? (
        <Shimmer type={ShimmerType.CUSTOM} className="w-16 h-4" />
      ) : (
        <div className="text-lg">
          {metricText}
        </div>
      )}
      {!!onExport ? (
        <div
          onClick={_export && !exportLoading && !disabled ? onExport : () => { }}
          className={`${(_export && !exportLoading && !disabled)
            ? 'cursor-pointer text-wc-blue decoration-wc-blue decoration-2 hover:underline text-sm'
            : `cursor-default text-medium-gray ${exportLoading ? 'animate-pulse' : ''}`
            }`}
        >
          <div className='flex items-center'>
            Export Data
            {disabled && <Info className="ml-1" text={`Unable to export ${title} data as it has been more than 30 days since campaign start date.`} />}
          </div>
        </div>
      ) : (
        footer
      )}
    </div>
  );
};

export const RecurringCampaignsDetails = () => {
  const { id } = useParams<any>();
  const [recurringCampaign, setRecurringCampaign] = useState<IRecurringCampaign>();
  const navigate = useNavigate();
  const [showMetrics, setShowMetrics] = useState(false);
  const [clientName, setClientName] = useState('');
  const [providerName, setProviderName] = useState('');
  const [pauseLoading, setPauseLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [showPauseModal, setShowPauseModal] = useState(false);
  const [isCampaignPauseLoading, setIsCampaignPauseLoading] = useState(false);
  const [isCampaignActive, setIsCampaignActive] = useState(false);
  const [campaignId, setCampaignId] = useState('');
  const { addNotification } = useNotifications();
  const [metricsDeliveredRateLoading, setMetricsDeliveredRateLoading] = useState(true);
  const [metricsClickRateLoading, setMetricsClickRateLoading] = useState(true);
  const [metricsOptoutRateLoading, setMetricsOptoutRateLoading] = useState(true);
  const [metricsClickRate, setMetricsClickRate] = useState<IRecurringCampaignMetricsClickRate>();
  const [metricsOptoutRate, setMetricsOptoutRate] = useState<IRecurringCampaignMetricsOptoutRate>();
  const [metricsDeliveredRate, setMetricsDeliveredRate] = useState<IRecurringCampaignMetricsDeliveredRate>();
  const [{ data: customDataPointsData, loading: customDataPointsLoading }] = useCustomDataPoints({ personalized: true, skip: 0, take: 1000, isActive: true });
  const [tableOptions, setTableOptions] = useState({
    ...defaultRecurringCampaignCampaignsTableOptions,
  } as ISearchRequest);
  const [{ data: campaignsData, loading: campaignsLoading }, refetchCampaigns] = useRecurringCampaignCampaigns(id!, { ...tableOptions });

  const customDataPoints = customDataPointsData?.records ?? [];
  const isActive = [RecurringCampaignStatusEnum.ACTIVE, RecurringCampaignStatusEnum.SCHEDULING].includes(
    recurringCampaign?.status ?? RecurringCampaignStatusEnum.COMPLETE
  );

  useEffect(() => {
    if (id) {
      getRecurringCampaign(id);
    }
  }, [id]);

  useEffect(() => {
    if (id && showMetrics && !metricsClickRate) {
      getRecurringCampaignClickRate(id);
    }
    if (id && showMetrics && !metricsOptoutRate) {
      getRecurringCampaignOptoutRate(id);
    }
    if (id && showMetrics && !metricsDeliveredRate) {
      getRecurringCampaignDeliveredRate(id);
    }
  }, [showMetrics]);

  const handleRecurringCampaignPause = async () => {
    try {
      setPauseLoading(true);
      await axiosPatch(`/recurring-campaigns/${id}`, {
        status: isActive ? RecurringCampaignStatusEnum.PAUSED : RecurringCampaignStatusEnum.ACTIVE
      });
      await getRecurringCampaign(id ?? '');

      addNotification({
        header: !isActive ? 'Campaign resumed successfully' : 'Campaign paused successfully',
      });

    } catch (err) {
      console.error(err);
    } finally {
      setPauseLoading(false);
    }
    setPauseLoading(false);
  };

  const getRecurringCampaign = async (id: string) => {
    const recurringCampaign = await axiosGet<IHttpResponse<IRecurringCampaign>>(`/recurring-campaigns/${id}`);
    setRecurringCampaign(recurringCampaign.data);

    const [client, providerName] = await Promise.all([
      axiosGet<IHttpResponse<IRecurringCampaign>>(`/clients/${recurringCampaign.data.clientId}`),
      axiosGet<IHttpResponse<string>>(`/providers/${recurringCampaign.data.providerId}`)
    ]);
    setClientName(client.data.name);
    setProviderName(convertProviderName(providerName.data));
  };

  const getRecurringCampaignClickRate = async (id: string) => {
    try {
      setMetricsClickRateLoading(true);

      const metrics = await axiosGet<IHttpResponse<IRecurringCampaignMetricsClickRate>>(`/recurring-campaigns/${id}/click-rate`);
      setMetricsClickRate(metrics.data);
    } catch (e) {
      addNotification({
        header: 'Failed to load click rate',
        type: NotificationType.FAILURE
      });
      setMetricsClickRate({
        clickRate: null
      });
    } finally {
      setMetricsClickRateLoading(false);
    }
  };

  const getRecurringCampaignOptoutRate = async (id: string) => {
    try {
      setMetricsOptoutRateLoading(true);

      const metrics = await axiosGet<IHttpResponse<IRecurringCampaignMetricsOptoutRate>>(`/recurring-campaigns/${id}/optout-rate`);
      setMetricsOptoutRate(metrics.data);
    } catch (e) {
      addNotification({
        header: 'Failed to load opt-out rate',
        type: NotificationType.FAILURE
      });
      setMetricsOptoutRate({
        optoutRate: null
      });
    } finally {
      setMetricsOptoutRateLoading(false);
    }
  };

  const getRecurringCampaignDeliveredRate = async (id: string) => {
    try {
      setMetricsDeliveredRateLoading(true);

      const metrics = await axiosGet<IHttpResponse<IRecurringCampaignMetricsDeliveredRate>>(`/recurring-campaigns/${id}/delivered-rate`);
      setMetricsDeliveredRate(metrics.data);
    } catch (e) {
      addNotification({
        header: 'Failed to load delivered rate',
        type: NotificationType.FAILURE
      });
      setMetricsDeliveredRate({
        deliveredRate: null
      });
    } finally {
      setMetricsDeliveredRateLoading(false);
    }
  };

  const handleRecurringCampaignDelete = async () => {
    setPauseLoading(true);
    try {
      await axiosPatch(`/recurring-campaigns/${id}/delete`, {});
      addNotification({
        header: 'Recurring Campaign Deleted'
      });
      navigate('/recurring-campaigns/');
    } catch (err) {
      addNotification({
        header: 'Recurring Campaign Deleted Failed',
        type: NotificationType.FAILURE
      });
    }
    finally {
      setPauseLoading(false);
    }
  };

  const convertProviderName = (providerName: string) => {
    switch (providerName) {
      case ProviderName.TELNYX_TOLL_FREE:
      case ProviderName.BANDWIDTH_TOLL_FREE:
        return 'Toll Free';
      case ProviderName.TELNYX_10DLC:
      case ProviderName.BANDWIDTH_10DLC:
      case ProviderName.SINCH_10DLC:
        return '10DLC';
      case ProviderName.SINCH_SHORT_CODE:
        return 'Short Code';
      default:
        return '-';
    }
  };

  function getFormattedDate(date: Date) {

    const year = new Date(date).getFullYear();
    const month = (1 + new Date(date).getMonth()).toString().padStart(2, '0');
    const day = new Date(date).getDate().toString().padStart(2, '0');

    return month + '/' + day + '/' + year;
  }

  const getScheduleMessage = (recurringCampaign: IRecurringCampaign) => {
    const audiences = recurringCampaign.audiences ?? [];
    const sendingStartsAt = recurringCampaign.sendingStartsAt;
    const startsAt = recurringCampaign.startsAt;
    const endsAt = recurringCampaign.hasNoEndDate ? recurringCampaign.endsAt : undefined;

    if (isEmpty(audiences) || !sendingStartsAt || !startsAt) {
      return '';
    }

    const audienceNames = audiences?.map(audience => audience?.name) ?? [];
    const audienceNamesMessage = getOxfordCommaString(audienceNames);

    const startDate = getFormattedDate(startsAt);
    const time = new Date(sendingStartsAt).toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric'
    });

    if (endsAt) {
      const endDate = getFormattedDate(endsAt);
      return `Your messages are scheduled to send to ${audienceNamesMessage} at ${time} everyday, from ${startDate} to ${endDate}.`;
    } else {
      return `Your messages are scheduled to send to ${audienceNamesMessage} at ${time} everyday, from ${startDate}.`;
    }
  };

  function handleExportLinkClicks(): void {
    // TODO implement
    return;
  }

  function handleExportOptouts(): void {
    // TODO implement
    return;
  }

  function handleExportDeliverability(): void {
    // TODO implement
    return;
  }

  const columns = getCampaignColumns(
    {
      name: (item: IRecurringCampaignInstanceOverview) => {
        navigate(`/recurring-campaigns/${id}/campaigns/${item.id}`);
      },
    },
    {
      name: (item: IRecurringCampaignInstanceOverview) => {
        if (!item) {
          return;
        }

        return <>
          <div className='mb-2 text-lg text-sky-600 hover:text-sky-900 hover:underline'>{item.name}</div>
          <div className='flex flex-row items-center text-xs text-medium-gray fill-medium-gray'>
            <ClockIcon width={15} className='mr-2' />
            <span>{format(new Date(item.startsAt), 'Pp')}</span>
          </div>
        </>;
      },
      deliveredProgress: (item: IRecurringCampaignInstanceOverview) => <CampaignDeliveredProgressBar campaign={item as unknown as ICampaignEntity} />,
      clickRate: (item: IRecurringCampaignInstanceOverview) => isFinite(item?.clickRate) ? `${item.clickRate.toFixed(1)}%` : '0%',
      optoutRate: (item: IRecurringCampaignInstanceOverview) => isFinite(item?.optoutRate) ? `${item.optoutRate.toFixed(1)}%` : '0%',
    },
    [{
      label: 'Pause/Resume',
      action: async (row: IRecurringCampaignInstanceOverview) => {
        setIsCampaignActive(row.isActive);
        setShowPauseModal(true);
        setCampaignId(row.id);
      }
    }]
  );

  const handleRefetch = async () => {
    try {
      await refetchCampaigns();
    } catch (error) { }
  };

  const handleCampaignPauseResume = async () => {
    try {
      setIsCampaignPauseLoading(true);

      const request: UpdateCampaignRequest = { isActive: !isCampaignActive };
      await axiosPost(`/campaigns/${campaignId}`, request);
      addNotification({
        header: !isCampaignActive ? 'Campaign resumed successfully' : 'Campaign paused successfully',
      });

    } catch (error) {
      console.error(error);
    } finally {
      setIsCampaignPauseLoading(false);
    }
  };

  return (<>   <div className="px-20">
    <h1 className="flex items-center text-black basis-full">
      <div className='flex flex-row items-center justify-center'>
        <div>{recurringCampaign?.name}</div>
      </div>
      <Button className="ml-auto" onClick={() => navigate('/recurring-campaigns/')} variant={ButtonVariantEnum.SECONDARY}>
        BACK
      </Button>
    </h1>
    {recurringCampaign && (
      <h3 className="mt-2 font-semibold text-wc-blue">
        {getScheduleMessage(recurringCampaign)}
      </h3>
    )
    }

  </div>
    <div className="flex items-center px-20 pt-8 space-x-4">
      <h4 className="text-dark-gray">View:</h4>
      <Chip color={'bg-glacier'} active={!showMetrics} onClick={() => setShowMetrics(false)}>
        Campaign Details
      </Chip>
      <Chip color={'bg-glacier'} active={showMetrics} onClick={() => setShowMetrics(true)}>
        Performance Metrics
      </Chip>
    </div>
    {!showMetrics ? (
      <div className="flex px-20 pb-4 mt-8">
        <div className="basis-2/3">
          <div className="space-y-8">
            {/* HEADER */}
            <div className="flex">
              <h2>Campaign Details</h2>
            </div>
            {/* BASIC INFO */}
            <div className="flex details-row">
              <Static shimmer={10} title="CLIENT">
                {clientName}
              </Static>
              <Static shimmer={10} title="STATUS">
                {recurringCampaign?.status ? recurringCampaign.status.charAt(0) + recurringCampaign.status.substring(1).toLowerCase() : ''}
              </Static>
            </div>
            {/* START/END DATE */}
            <div className="flex details-row">
              <Static shimmer={10} title="START DATE">
                {recurringCampaign?.startsAt ? moment(recurringCampaign?.startsAt).format('L, LT') : ''}
              </Static>
              <Static shimmer={10} title="END DATE">
                {recurringCampaign?.startsAt ? moment(recurringCampaign?.endsAt).format('L, LT') : ''}
              </Static>
            </div>
            {/* MESSAGE/NUMBER TYPE */}
            <div className="flex details-row">
              <Static shimmer={10} title="MESSAGE">
                {recurringCampaign?.messageType}
              </Static>
              <Static shimmer={10} title="NUMBER">
                {providerName}
              </Static>
            </div>
            {/* LISTS */}
            <div className="flex details-row">
              <Static shimmer={10} title="Audiences">
                {recurringCampaign?.audiences?.length > 0 && (
                  <ol>
                    {recurringCampaign?.audiences?.map((cl, index) => (
                      <li key={cl.id}>{index + 1}. {cl.name}</li>
                    ))}
                  </ol>
                )}
              </Static>
              <Static shimmer={10} title="Suppressions">
                {recurringCampaign?.suppressions?.length > 0 ? (
                  <ol>
                    {recurringCampaign?.suppressions?.map((cl, index) => (
                      <li key={cl.id}>{index + 1}. {cl.name}</li>
                    ))}
                  </ol>
                ) : (<ol>-</ol>)
                }
              </Static>
              <Static shimmer={10} title="EXTERNAL ID">
                {!!recurringCampaign && (recurringCampaign?.externalId || '-')}
              </Static>
            </div>
            {/* CLICK TRACKING */}
            <div className="flex flex-wrap">
              <div className="basis-1/2">
                <GlacierCheckbox
                  id="click-tracking"
                  label="Enable Click Tracking"
                  readOnly
                  checked={recurringCampaign?.clickTrackingEnabled ?? false}
                />
              </div>
              <div className="basis-1/2">
              </div>
              {recurringCampaign?.clickTrackingEnabled && (
                <>
                  <div className="mt-4 basis-1/2">
                    <Static shimmer={10} title="URL">
                      {recurringCampaign?.url}
                    </Static>
                  </div>
                  <div className="mt-4 basis-1/2">
                    <Static shimmer={10} title="DOMAIN">
                      {recurringCampaign.domain}
                    </Static>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col ml-auto">
          <MessagePreview campaign={recurringCampaign} domain={recurringCampaign?.domain} customDataPoints={customDataPoints} />
          {recurringCampaign?.messageType === MessageTypeEnum.MMS && (
            <div className="flex">
              <div className="pl-8 mx-auto mt-1">
                <a href={recurringCampaign?.mediaUrl!} target="_blank" download className="text-wc-blue hover:underline">
                  Download Media File
                </a>
              </div>
            </div>
          )}
          <div className="pl-8 mx-auto mt-2 body-text-small">
            Segments: {recurringCampaign?.segmentsCount} | Characters: {recurringCampaign?.charactersCount}/1000
          </div>
          <div className="pl-8 mx-auto mt-2 body-text-small">
          </div>
          {/* PAUSE CAMPAIGN */}
          {(!!recurringCampaign && recurringCampaign.status === RecurringCampaignStatusEnum.PAUSED) ?
            (<div className="flex items-center justify-between pl-8 mx-auto mt-4 space-x-4">
              <Button
                onClick={() => setShowModal(true)}
                isLoading={pauseLoading}
                variant={ButtonVariantEnum.SECONDARY}
                disabled={!recurringCampaign}
              >
                RESUME
              </Button>

              <Button
                onClick={() => setshowDeleteModal(true)}
                isLoading={pauseLoading}
                variant={ButtonVariantEnum.DELETE}
                disabled={!recurringCampaign}
              >
                DELETE
              </Button>
            </div>) :
            (<div className="flex flex-col pl-8 mx-auto mt-4 space-y-4">
              <Button
                onClick={() => setShowModal(true)}
                isLoading={pauseLoading}
                variant={ButtonVariantEnum.SECONDARY}
                disabled={!recurringCampaign}
              >
                PAUSE RECURRING CAMPAIGN
              </Button>
            </div>)

          }
        </div>
      </div>
    ) : (
      <>
        <div className="flex px-20 pb-4 my-8">
          <div className="space-y-8">
            {/* HEADER */}
            <div className="flex">
              <h2>Performance Metrics</h2>
            </div>
            {/* METRICS L1 */}
            <div>
              <div className="flex justify-between mb-8 space-x-8">
                <MetricCard
                  title="DELIVERABILITY RATE"
                  _export={!!recurringCampaign}
                  loading={metricsDeliveredRateLoading}
                  exportLoading={metricsDeliveredRateLoading}
                  metric={metricsDeliveredRate?.deliveredRate}
                  onExport={() => handleExportDeliverability()}
                />
                <MetricCard
                  title="CLICK RATE"
                  _export={!!recurringCampaign}
                  metric={metricsClickRate?.clickRate}
                  onExport={() => handleExportLinkClicks()}
                  loading={metricsClickRateLoading}
                  exportLoading={metricsClickRateLoading}
                // TODO: what do?
                // if the start date of the campaign was 30 or more days ago. disable export data button for click rate metric
                // disabled={recurringCampaign && Math.round((new Date().getTime() - new Date(recurringCampaign.startsAt).getTime()) / (1000 * 3600 * 24)) > 30}
                />
                <MetricCard
                  title="OPT-OUT RATE"
                  _export={!!recurringCampaign}
                  loading={metricsOptoutRateLoading}
                  exportLoading={metricsOptoutRateLoading}
                  metric={metricsOptoutRate?.optoutRate}
                  onExport={() => handleExportOptouts()}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="px-20 space-y-8">
          <h2>Campaign Metrics</h2>
          <Table
            columns={columns}
            items={campaignsData?.records}
            count={campaignsData?.totalCount}
            loading={campaignsLoading}
            tableSearchOptions={tableOptions}
            onSearchOptionChange={(request) => setTableOptions(request)}
            paginate
            onRefresh={handleRefetch}
          />
        </div>
      </>
    )}

    <RecurringCampaignPauseResumeModal
      isCampaignActive={isActive ?? false}
      showModal={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      onSuccess={() => {
        handleRecurringCampaignPause();
        setShowModal(false);
      }}
      onCancel={() => {
        setShowModal(false);
      }}
    />

    <RecurringCampaignDeleteModal
      showModal={showDeleteModal}
      onClose={() => {
        setshowDeleteModal(false);
      }}
      onSuccess={() => {
        handleRecurringCampaignDelete();
        setshowDeleteModal(false);
      }}
      onCancel={() => {
        setshowDeleteModal(false);
      }}
    />

    <CampaignPauseResumeModal
      isCampaignActive={isCampaignActive}
      showModal={showPauseModal}
      isLoading={isCampaignPauseLoading}
      onClose={() => {
        setShowPauseModal(false);
      }}
      onSuccess={async () => {
        await handleCampaignPauseResume();
        setShowPauseModal(false);
        await refetchCampaigns();
      }}
      onCancel={() => {
        setShowPauseModal(false);
      }}
    />
  </>);
};



export default RecurringCampaignsDetails;
