import { ActionWithRulesProps } from 'react-querybuilder';
import { Button } from '../../Buttons/Button';

export const CustomRemoveButton = (props: ActionWithRulesProps) => {
  return (
    <Button className="ml-0 mb-8 bg-gray-100 text-red-600 font-extrabold hover:bg-gray-100 shadow-none text-lg" onClick={props.handleOnClick} disabled={props.disabled}>
      X
    </Button>
  );
};
