import { Button, ButtonVariantEnum, Modal } from '@Wonder-Cave/ui';

interface ICampaignPauseResumeModalProps {
  isCampaignActive: boolean;
  showModal: boolean;
  isLoading?: boolean;
  onClose: () => void;
  onSuccess: () => void;
  onCancel: () => void;
}

const CampaignPauseResumeModal = ({
  isCampaignActive,
  isLoading,
  showModal,
  onClose,
  onSuccess,
  onCancel,
}: ICampaignPauseResumeModalProps) => {
  return (
    <Modal title="Are you sure?" show={showModal} onClose={onClose}>
      <h3 className="px-8 text-center">
        {isCampaignActive
          ? 'Once you pause a campaign, it will no longer send messages until you resume the campaign.'
          : 'Once you resume a campaign, it will start sending messages again.'}
      </h3>
      <div className="flex justify-center mt-8 mb-4">
        <Button variant={ButtonVariantEnum.SECONDARY} onClick={onCancel} className="mr-4 uppercase" disabled={isLoading}>
          CANCEL
        </Button>
        <Button onClick={onSuccess} variant={ButtonVariantEnum.PRIMARY} className="uppercase" isLoading={isLoading}>
          {isCampaignActive ? 'PAUSE CAMPAIGN' : 'RESUME CAMPAIGN'}
        </Button>
      </div>
    </Modal>
  );
};

export default CampaignPauseResumeModal;
