import { Autocomplete } from '@Wonder-Cave/ui';
import { useSuppressions } from 'apps/a2p-portal/src/hooks/useSuppressions';
import { IDropdownValue } from '../Dropdown';

interface ISuppressionDropdownProps {
  value: IDropdownValue[] | IDropdownValue | undefined;
  onChange: (newValue: IDropdownValue[] | IDropdownValue | undefined) => void;
  onBlur: () => void;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError?: boolean;
  multiple?: boolean;
}

const NewSuppressionDropdown = ({
  value,
  onChange,
  onBlur,
  disabled,
  errorMessage,
  showError,
  multiple
}: ISuppressionDropdownProps): JSX.Element => {
  let suppressions: IDropdownValue[] = [];
  const [{ data: audienceData, loading: audienceLoading, error: audienceError }] = useSuppressions();
  suppressions =
    audienceData?.records.map((suppression) => ({
      label: suppression.name ?? '',
      value: suppression.id ?? '',
    })) ?? [];

  return (
    <Autocomplete
      label="Suppression"
      value={value as any}
      options={suppressions}
      loading={audienceLoading}
      onChange={onChange}
      onBlur={onBlur}
      showError={showError}
      error={errorMessage}
      disabled={disabled}
      multiple={multiple}
    />
  );
};

export default NewSuppressionDropdown;
