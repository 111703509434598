import { IGetAllRecurringCampaignsRequest, IHttpPaginatedResponse, IRecurringCampaignInstanceOverview, ISearchRequest } from '@shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';

export default function useRecurringCampaigns(recurringCampaignId: string, request: ISearchRequest): UseAxiosResult<IHttpPaginatedResponse<IRecurringCampaignInstanceOverview>> {
  const params: IGetAllRecurringCampaignsRequest = {
    skip: request?.pagination?.skip ?? 0,
    take: request?.pagination?.take ?? 25
  };

  return useAxios<IHttpPaginatedResponse<IRecurringCampaignInstanceOverview>>({
    url: `recurring-campaigns/${recurringCampaignId}/campaigns`,
    method: 'GET',
    params
  });
}
