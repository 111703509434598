import { MessageTypeEnum, ProviderNumberTypeEnum, S3BucketEnum } from '@shared/enums';
import { GetSegmentsContactCountRequest, ICampaignVolumeEntity, IHttpResponse, ISignedUrlRequest, SocketName } from '@shared/models';
import { isNil, uuid } from '@shared/services';
import { Button, ButtonVariantEnum, CalculateIcon, Radio, TextInput } from '@Wonder-Cave/ui';
import { IDropdownValue } from '@Wonder-Cave/ui/dist/Autocomplete';
import { axiosGet, axiosPost, getQueryParamString } from 'apps/a2p-portal/src/authAxios';
import { socketManager } from 'apps/a2p-portal/src/contexts/SocketContext';
import { useCustomDataPoints } from 'apps/a2p-portal/src/hooks/useCustomDataPoints';
import { getCustomDataPointFields } from 'apps/a2p-portal/src/providers/custom-data-point.provider';
import { formatBackendMongoDBQuery } from 'apps/a2p-portal/src/providers/mongodb.provider';
import { isFileLocalSize, isFileMediaType, isFileTollFreeSize } from 'apps/a2p-portal/src/providers/validation.provider';
import axios from 'axios';
import { FastField } from 'formik';
import { useEffect, useState } from 'react';
import { Field, RuleType } from 'react-querybuilder';
import NewAudienceDropdown from '../../shared/Form/Dropdowns/NewAudienceDropdown';
import NewSuppressionListDropdown from '../../shared/Form/Dropdowns/NewSuppressionListDropdown';
import NewQueryBuilder from '../../shared/Form/NewQueryBuilder/NewQueryBuilder';
import { OldFileUpload } from '../../shared/Form/OldFileUpload';
import { IRecurringCampaignForm } from '../types';
import './recurring-campaigns-plan.scss';

interface IPlanProps {
  values?: any;
  errors?: any;
  touched?: any;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldTouched?: (field: string, touched: boolean) => void;
  setFieldError?: (field: string, msg: string | undefined) => void;
  handleChange?: () => void;
  handleBlur?: () => void;
  onSubmit: (formikProps) => void;
  setLeads: (leads: any) => void;
  setLoading: (value: boolean) => void;
  leads: number;
  submissionError?: string;
}

const Plan = ({
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  setFieldError,
  handleChange,
  handleBlur,
  setLeads,
  setLoading,
  leads,
  submissionError
}: IPlanProps) => {
  const [client, setClient] = useState<IDropdownValue>();
  const [inlineSuppressionFields, setInlineSuppressionFields] = useState<Field[]>([]);
  const [contactCountLoading, setContactCountLoading] = useState(false);
  const [calculateLoading, setCalculateLoading] = useState(false);
  const [contactCount, setContactCount] = useState(NaN as number);
  const [campaignVolumeId, setCampaignVolumeId] = useState('');
  const [{ data: customDataPointsResponse }, getCustomDataPoints] = useCustomDataPoints({ skip: 0, take: 1000, isActive: true, personalized: true });
  const customDataPoints = customDataPointsResponse?.records ?? [];
  const calculateContactCount = async (values: IRecurringCampaignForm) => {
    try {
      setCalculateLoading(true);

      const response = await axiosPost<IHttpResponse<ICampaignVolumeEntity>>('/campaign-volumes');
      const campaignVolume = response?.data;

      const request: GetSegmentsContactCountRequest = {
        clientId: client?.value,
        audienceIds: values?.audiences?.map(audience => audience?.value) ?? [],
        suppressionIds: values?.suppressions?.map(suppression => suppression?.value) ?? [],
        campaignVolumeId: campaignVolume.id,
        inlineSuppressionFrontendFilter: hasInlineSuppression(values) ? values?.frontendInlineSuppression : null,
        inlineSuppressionBackendFilter: hasInlineSuppression(values)
          ? formatBackendMongoDBQuery(values?.frontendInlineSuppression, customDataPoints)
          : null
      };

      socketManager?.sendMessage<GetSegmentsContactCountRequest>(SocketName.SEGMENT_CONTACT_COUNT, request);

      setContactCount(NaN);
      setCampaignVolumeId(campaignVolume.id);
    } catch (error) {
      console.log(error);
      setContactCount(NaN);
    }
  };
  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        if (campaignVolumeId?.length > 0) {
          const response = await axiosGet<IHttpResponse<ICampaignVolumeEntity>>(`/campaign-volumes/${campaignVolumeId}`);
          const campaignVolume = response?.data;

          if (campaignVolume && !campaignVolume.isCalculating) {
            setCalculateLoading(false);
            setContactCount(campaignVolume?.count ?? NaN);
            setContactCountLoading(false);
            setCampaignVolumeId('');
          }
        }
      } catch (error) {
        console.error(error);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [campaignVolumeId]);


  useEffect(() => {
    if (values.showSuppressions === true) {
      const getFields = async () => {
        const fields = await getCustomDataPointFields({
          rules: values?.frontendInlineSuppression?.rules as RuleType[],
          isActive: true,
          personalized: true
        });
        setInlineSuppressionFields(fields);
      };
      getFields();
    }
  }, [values.showSuppressions]);

  const hasInlineSuppression = (values: IRecurringCampaignForm): any => {
    return (values?.frontendInlineSuppression?.rules?.length ?? 0) > 0;
  };

  const uploadMediaFile = async (
    file: File,
    numberType: ProviderNumberTypeEnum,
    setFieldValue: (field: string, value: any) => void
  ) => {
    const validateFileSize = numberType === ProviderNumberTypeEnum.LOCAL ? isFileLocalSize : isFileTollFreeSize;

    if (!file || !isFileMediaType(file) || !validateFileSize(file)) {
      setFieldValue('mediaUrl', '');
      setFieldValue('mediaName', '');
      return;
    }

    const mediaFileKey: string = uuid();

    const s3SignedUrlRequest: ISignedUrlRequest = {
      bucket: S3BucketEnum.MMS,
      key: mediaFileKey,
      action: 'putObject',
      contentType: file.type,
    };

    const s3MediaUrlRequest: ISignedUrlRequest = {
      bucket: S3BucketEnum.MMS,
      key: mediaFileKey,
      action: 'getObject',
      contentType: file.type,
      expiresIn: 31536000, // 1 Year
    };

    try {
      setLoading(true);
      const {
        data: uploadUrl,
      } = await axiosGet<IHttpResponse<string>>(`/signed-s3-url?${getQueryParamString(s3SignedUrlRequest)}`);

      await axios.put(uploadUrl, file, {
        headers: {
          'Content-Type': file.type,
          'Content-Disposition': 'attachment',
        },
      });

      const {
        data: mediaUrl,
      } = await axiosGet<IHttpResponse<string>>(`/signed-s3-url?${getQueryParamString(s3MediaUrlRequest)}`);
      setFieldValue('mediaUrl', mediaUrl);
      setFieldValue('mediaName', file.name);
    } catch (error) {
      console.error('Error when attempting to upload media file');
    } finally {
      setLoading(false);
    }
  };


  return (
    <><div className="flex flex-wrap space-y-8">
      <div className="campaign-plan">
        <h2 className="mb-8 text-black basis-full ">Plan your message</h2>
        <FastField
          component={TextInput}
          id="name"
          name="name"
          label="RECURRING CAMPAIGN TITLE"
          placeholder="Name your campaign"
          value={values.name}
          error={touched.name ? errors.name : ''}
          onChange={handleChange}
          onBlur={handleBlur} />
        <Radio
          label="Message Type"
          options={[
            {
              label: 'SMS',
              value: MessageTypeEnum.SMS,
            },
            {
              label: 'MMS',
              value: MessageTypeEnum.MMS,
            },
          ]}
          value={values.messageType}
          onChange={(newValue) => {
            setFieldValue!('messageType', newValue);
            setFieldTouched!('messageType', true);
          }}
          grow
          error={touched.messageType && errors.messageType?.value} />
        <div className=""></div>
        {values.messageType === MessageTypeEnum.MMS && (
          <>
            <div>
              <OldFileUpload
                id="mediaFile"
                name="mediaFile"
                label="Media File"
                fileName={values.mediaName}
                accepts="image/*, video/*, .pdf"
                value={values.mediaFile}
                error={errors.mediaFile as any}
                onBlur={handleBlur}
                placeholder="Select a media file"
                onChange={(fileUploadEvent: any) => {
                  const file = fileUploadEvent.target.files[0];
                  setFieldTouched!('mediaFile', true);
                  setFieldValue!('mediaFile', file);
                  if (file.type.match('video.*')) {
                    setFieldValue!('isMMSVideo', true);
                  } else {
                    setFieldValue!('isMMSVideo', false);
                  }
                  uploadMediaFile(file, ProviderNumberTypeEnum.LOCAL, setFieldValue!);
                }} />
            </div>
            <div>{/* SPACER */}</div>
          </>
        )}
        <div></div>
        <div className="border-b-2 border-gray shrink-0"></div>
        <h2 className="mb-8 text-black basis-full">Select Segments</h2>

        <NewAudienceDropdown
          value={values.audiences}
          onChange={(newValue) => {
            setFieldValue!('audiences', newValue);
          }}
          onBlur={() => {
            setFieldTouched!('audiences', true);
          }}
          showError={!!touched.audiences}
          errorMessage={errors.audiences?.toString()}
          multiple={true} />


        <NewSuppressionListDropdown
          value={values.suppressions}
          onChange={(newValue) => {
            setFieldValue!('suppressions', newValue);
          }}
          onBlur={() => {
            setFieldTouched!('suppressions', true);
          }}
          errorMessage={errors.suppressions as string}
          showError={!!(touched.suppressions && errors.suppressions)}
          multiple={true} />


        <FastField
          component={TextInput}
          id="externalId"
          name="externalId"
          label={<div className="flex items-center mb-5">
            <h5>{'EXTERNAL ID (OPTIONAL)'}</h5>
            {/* <Info className="ml-1" text="Include an ID from an external source." /> */}
          </div>}
          value={values.externalId}
          errors={errors.externalId}
          touched={touched.externalId}
          onChange={handleChange} />
        <Radio
          label="Do you need to ADD Inline Suppressions?"
          options={[
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ]}
          value={values.showSuppressions}
          grow
          onChange={(newValue) => {
            setFieldValue!('showSuppressions', newValue);
          }} />

      </div>
      {values.showSuppressions && (
        <><h2 className="mb-4 text-black basis-full">Inline Suppression</h2>
          <span className='plan-query-builder'>
            <NewQueryBuilder
              fields={inlineSuppressionFields}
              query={values.frontendInlineSuppression}
              setQuery={(newValue) => {
                setFieldValue!('frontendInlineSuppression', newValue);
              }}
              maxRules={5}
              hideAddQuery />
          </span>

        </>
      )}
    </div><div>
        <div className='flex justify-between mt-8'>
          <div className="flex items-center">
            <Button
              variant={ButtonVariantEnum.GLACIER}
              disabled={values.audiences.length === 0 || calculateLoading}
              onClick={() => calculateContactCount(values)}
              trailingIcon={<div className={calculateLoading ? 'spin' : ''}>
                <CalculateIcon />
              </div>}
            >
              Calculate Campaign Volume
            </Button>
            {!contactCountLoading && !isNil(contactCount) && (
              <h3 className="ml-8 text-wc-blue">{contactCount} messages</h3>
            )}
          </div>
          {submissionError && <h3 className='flex items-center mt-3 text-red-600'>{submissionError}</h3>}
        </div>
      </div></>

  );
};

export default Plan;
