import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { Fragment } from 'react';
import LoadingIndicator from '../LoadingIndicator';
import { ErrorMessage } from './ErrorMessage';

interface IDropdownProps {
  error?: string;
  value?: IDropdownValue | null;
  label?: string;
  disabled?: boolean;
  loading?: boolean;
  onChange: (selectedValue: IDropdownValue) => void;
  onBlur?: any;
  options: IDropdownValue[];
  placeholder?: string;
  full?: boolean;
  labelColor?: string;
  className?: string;
}

export interface IDropdownValue {
  label: string;
  value: any;
  additionalData?: any;
}

const Dropdown = ({
  options,
  value: selectedOption,
  label,
  disabled,
  loading,
  onChange,
  onBlur,
  placeholder,
  error,
  full,
  labelColor,
  className,
}: IDropdownProps): JSX.Element => {
  return (
    <div onBlur={onBlur} className={`${full ? 'w-full' : ''} ${className ?? ''}`}>
      <>
        <Listbox value={selectedOption} onChange={onChange}>
          {({ open }) => (
            <>
              <Listbox.Label
                className={`block text-sm font-medium ${labelColor ?? 'text-gray-700'} dark:text-slate-400`}
              >
                {label}
              </Listbox.Label>
              <div className={`relative ${!!label ? 'mt-1' : ''}`}>
                {loading ? (
                  <LoadingIndicator />
                ) : (
                  <Listbox.Button
                    className={`relative w-full py-2 pl-3 pr-10 text-left ${disabled ? 'bg-gray-100 dark:bg-slate-900' : 'bg-white dark:bg-slate-700'
                      } border border-gray-300 dark:border-slate-800 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm`}
                  >
                    {selectedOption?.value !== undefined && selectedOption?.value !== null ? (
                      <span className="block truncate">{selectedOption.label}</span>
                    ) : (
                      <span className="block text-gray-500 truncate">{placeholder ?? 'Select a value'}</span>
                    )}
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <SelectorIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Listbox.Button>
                )}

                <Transition
                  show={open && !disabled}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-20 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg dark:text-slate-400 dark:bg-slate-700 max-h-60 ring-1 ring-black ring-offset-0 ring-opacity-5 focus:outline-none sm:text-sm">
                    {options.map((option) => (
                      <Listbox.Option
                        key={option.value}
                        className={({ active }) => `cursor-default select-none relative py-2 pl-3 pr-9 ${active ? 'text-white bg-sky-600 dark:bg-slate-800' : 'text-gray-900 dark:text-slate-400'
                          } 
                    `}
                        value={option}
                      >
                        {({ selected, active }) => (
                          <>
                            <span className={`block truncate ${selected ? 'font-semibold' : 'font-normal'}`}>
                              {option.label}
                            </span>

                            {selected ? (
                              <span
                                className={`absolute inset-y-0 right-0 flex items-center pr-4 ${active ? 'text-white' : 'text-sky-600'
                                  }`}
                              >
                                <CheckIcon className="w-5 h-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
        <ErrorMessage show={!!error} message={error} />
      </>
    </div>
  );
};

export { Dropdown };

