import { LogoutOptions } from '@auth0/auth0-react';
import { Auth0User, ISearchRequest } from '@shared/models';
import { deleteNilValues } from '@shared/services';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import querystring from 'querystring';
import { logout } from './providers/auth0.provider';
import { getApiUrl } from './providers/sub-domain.service';

export interface TokenResponse {
  id_token?: string;
  access_token: string;
  refresh_token?: string;
  expires_in: number;
  scope?: string;
}

export interface ConfigureAxiosRequest {
  token: TokenResponse;
  auth0User: (Auth0User & any) | undefined;
  auth0Logout: (request?: LogoutOptions) => void;
}

let baseURL: string | undefined;
let authAxios: AxiosInstance | undefined;
let tokenResponse: TokenResponse;

export function configureAxiosWithAuth0(request: ConfigureAxiosRequest) {
  tokenResponse = request.token;
  baseURL = getApiUrl();

  authAxios = axios.create({ baseURL });

  authAxios.interceptors.request.use(
    (config) => addTokenToRequest(request.token, config),
    (error) => Promise.reject(error)
  );

  authAxios.interceptors.response.use(
    (response) => response,
    (error) => handleError(error, request)
  );

  return authAxios;
}

function addTokenToRequest(token: TokenResponse, config: AxiosRequestConfig<any>) {
  if (token) {
    config.headers!.Authorization = `Bearer ${token.access_token}`;
  }

  return config;
}

function handleError(error: any, request: ConfigureAxiosRequest) {
  const status = error?.response?.status;

  switch (status) {
    case 403:
      return handleForbidden(error);
    case 401:
      return handleUnauthorized(error, request);
  }

  return Promise.reject(error);
}

function handleForbidden(error: any) {
  window.location.href = '/campaigns';
  return Promise.reject(error);
}

function handleUnauthorized(error: any, request: ConfigureAxiosRequest) {
  console.log('Logging out...');
  console.error(error);
  logout(request);
  return Promise.resolve();
}

export function axiosPost<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D): Promise<R> {
  return authAxios ? axios.post<T, R, D>(url, data, getConfig<D>()) : Promise.reject('Axios has not been configured');
}

export function axiosGet<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: GetConfigRequest<D>): Promise<R> {
  return authAxios ? axios.get<T, R, D>(url, getConfig<D>(config)) : Promise.reject('Axios has not been configured');
}

export function axiosPut<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D): Promise<R> {
  return authAxios ? axios.put<T, R, D>(url, data, getConfig<D>()) : Promise.reject('Axios has not been configured');
}

export function axiosDelete<T = any, R = AxiosResponse<T>>(url: string): Promise<R> {
  return authAxios ? axios.delete<T, R>(url, getConfig()) : Promise.reject('Axios has not been configured');
}

export function axiosPatch<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D): Promise<R> {
  return authAxios
    ? axios.patch<T, R, D>(url, data, getConfig<D>())
    : Promise.reject('Axios has not been configured');
}

export function getQueryParamString(params: any) {
  return querystring.stringify(deleteNilValues(params));
}

export function getFilterValue(request?: ISearchRequest, fieldName?: string) {
  return request?.filters.find((f) => f.fieldName === fieldName)?.value;
}

export interface GetConfigRequest<D = any> {
  params?: any;
  config?: Partial<AxiosRequestConfig<D>>;
  removeToken?: boolean;
}

function getConfig<D = any>(request?: GetConfigRequest): AxiosRequestConfig<D> {
  const config: AxiosRequestConfig<D> = {
    ...(request?.config, {}),
    baseURL,
    headers: {
      Authorization: `Bearer ${tokenResponse.access_token}`,
    },
  };

  if (request?.removeToken) {
    config.headers = undefined;
  }

  if (request?.params) {
    config.params = request.params;
  }

  return config;
}


export function getTokenResponse() {
  return tokenResponse;
}