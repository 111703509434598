import 'draft-js/dist/Draft.css';
import BuildLinkTracking from './BuildLinkTracking';
import BuildMessage from './BuildMessage';
import BuildScheduleMessage from './BuildScheduleMessage';
import './recurring-campaigns-build.scss';
import { IBuildProps } from './types';

const Build = (props: IBuildProps) => {
  return (
    <div className='flex flex-col'>
      <h2 className='text-black basis-full mb-8'>
        Create Message
      </h2>

      <BuildMessage {...props}></BuildMessage>

      <div className='mb-4'></div>

      <BuildLinkTracking {...props}></BuildLinkTracking>

      <div className='w-full border-b-2 border-light-gray shrink-0 mt-[1rem] mb-[2rem]'></div>

      <h2 className='text-black basis-full mb-8'>
        Schedule Your Message
      </h2>

      <BuildScheduleMessage {...props}></BuildScheduleMessage>

      <div className='mb-8'>
      </div>
    </div>
  );
};

export default Build;
