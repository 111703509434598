import { Button, ButtonVariantEnum, Modal } from '@Wonder-Cave/ui';

interface IRecurringCampaignPauseResumeModalProps {
  isCampaignActive: boolean;
  showModal: boolean;
  isLoading?: boolean;
  onClose: () => void;
  onSuccess: () => void;
  onCancel: () => void;
}

const RecurringCampaignPauseResumeModal = ({
  isCampaignActive,
  showModal,
  isLoading,
  onClose,
  onSuccess,
  onCancel,
}: IRecurringCampaignPauseResumeModalProps) => {
  return (
    <Modal title="Are you sure?" show={showModal} onClose={onClose}>
      <h3 className="px-8 text-center">
        {isCampaignActive
          ? 'Once you pause a recurring campaign, it will no longer create campaigns at the scheduled time.'
          : 'Once you resume a recurring campaign, it will resume creating campaigns at the scheduled time.'}
      </h3>
      <div className="flex justify-center mt-8 mb-4">
        <Button variant={ButtonVariantEnum.SECONDARY} onClick={onCancel} className="mr-4 uppercase" isLoading={isLoading}>
          CANCEL
        </Button>
        <Button onClick={onSuccess} variant={ButtonVariantEnum.PRIMARY} className="uppercase" isLoading={isLoading}>
          {isCampaignActive ? 'PAUSE RECURRING CAMPAIGN' : 'RESUME RECURRING CAMPAIGN'}
        </Button>
      </div>
    </Modal>
  );
};

export default RecurringCampaignPauseResumeModal;
