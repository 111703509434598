import { FastField, Formik } from 'formik';
import { NewButton } from '../shared/Buttons/NewButton';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import NewDateTimePicker from '../shared/Form/NewDateTimePicker';
import NewTextInput from '../shared/Form/NewTextInput';
import { FlagIcon } from '../shared/Icons/FlagIcon';
import { IJourneyForm, journeyFormSchema } from './types';

interface IJourneyFormProps {
  loadingSubmit: boolean;
  handleSubmit: (formData: IJourneyForm) => Promise<void>;
}


const JourneyForm = ({ loadingSubmit, handleSubmit }: IJourneyFormProps) => {
  const initialJourneyState: IJourneyForm = {
    title: '',
    audience: {
      label: '',
      value: ''
    },
    startDate: new Date(),
    endDate: new Date()
  };
  return <Formik
    initialValues={initialJourneyState}
    validationSchema={journeyFormSchema}
    onSubmit={(values) => {
      handleSubmit(values);
    }}
  >
    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
      // Set form id so external submit button can still work
      <>
        <form id="campaign-form" onSubmit={handleSubmit}>
          <div className='flex flex-wrap items-start justify-between w-full max-w-4xl px-2 mx-auto'>
            <div className='pr-6 basis-1/2'>
              <FastField
                component={NewTextInput}
                id="title"
                name="title"
                label="Journey Title"
                placeholder="Name your journey"
                disabled={false}
                value={values.title}
                error={touched.title ? errors.title : ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className='basis-1/2'>
              {/* <NewAudienceDropdown
                value={values.audience}
                onChange={(newValue: IDropdownValue | IDropdownValue[] | undefined) => {
                  setFieldValue('audience', newValue);
                  setFieldTouched('audience');
                }}

                onBlur={() => {
                  setFieldTouched('audience');
                }}
                errorMessage={errors?.audience?.value as string}
                showError={!!(touched.audience && errors.audience)}
              /> */}
            </div>
            <div className='pr-6 mt-8 basis-1/2'>
              <NewDateTimePicker
                label={'START DATE & TIME'}
                value={values.startDate}
                onChange={(date: Date) => {
                  setFieldValue('startDate', date);
                  setFieldTouched('startDate');
                }}
                error={errors.startDate as string}
              />
            </div>
            <div className='mt-8 basis-1/2'>
              <NewDateTimePicker
                label={'END DATE & TIME'}
                value={values.endDate}
                onChange={(date: Date) => {
                  setFieldValue('endDate', date);
                  setFieldTouched('endDate');
                }}
                error={errors.endDate as string}
              />
            </div>
            <div className='flex m-auto mt-8'>
              <NewButton isLoading={loadingSubmit} trailingIcon={<FlagIcon />} variant={ButtonVariantEnum.PRIMARY}>Create New Journey</NewButton>
            </div>
          </div>
        </form>
      </>
    )}
  </Formik>;
};

export default JourneyForm;