import { RecurringCampaignCooldownPeriodEnum, RecurringCampaignFrequencyEnum } from '@shared/enums';
import { Autocomplete, Checkbox, DateTimePicker, Info, TextInput } from '@Wonder-Cave/ui';
import 'draft-js/dist/Draft.css';
import { FastField } from 'formik';
import { IDropdownValue } from '../../../shared/Form/Dropdown';
import { IBuildProps } from './types';

const BuildScheduleMessage = ({
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  setFieldError,
  handleChange,
  handleBlur,
  onSubmit,
  setLeads,
  setLoading,
  leads,
  submissionError,
  domains,
  readOnly,
}: IBuildProps) => {
  const frequencyOptions: IDropdownValue[] = [
    { label: 'Daily', value: RecurringCampaignFrequencyEnum.DAILY },
    { label: 'Weekly', value: RecurringCampaignFrequencyEnum.WEEKLY },
    { label: 'Monthly', value: RecurringCampaignFrequencyEnum.MONTHLY },
  ];

  const cooldownOptions: IDropdownValue[] = [
    { label: 'Months', value: RecurringCampaignCooldownPeriodEnum.MONTHS },
  ];

  return (
    <div className='flex flex-col'>
      <h4 className='mb-6 text-dark-gray'>
        1. Select Frequency: How often would you like your campaign to run?
      </h4>

      <div className='flex flex-row w-full'>
        <div className='flex-grow min-w-[calc(33%-1rem)] max-w-[calc(33%-1rem)] select-none'>
          <Autocomplete
            label='SELECT FREQUENCY'

            // only allow DAILY
            readOnly={true}
            disabled={true}
            value={frequencyOptions.find((option) => option.value === RecurringCampaignFrequencyEnum.DAILY)}

            options={frequencyOptions}
            onChange={(dropdownValue: IDropdownValue | undefined) => {
              setFieldTouched!('frequency', true);
              setFieldValue!('frequency', dropdownValue?.value);
            }}
            onBlur={(dropdownValue: IDropdownValue) => {
              setFieldTouched!('frequency', true);
              setFieldValue!('frequency', dropdownValue.value);
            }}
            showError={!!errors?.frequency}
            error={touched?.frequency ? errors?.frequency : ''}
          />
        </div>

        <div className='min-w-[1rem]'>
        </div>

        <div className='flex-grow min-w-[calc(33%-1rem)] max-w-[calc(33%-1rem)]'>
          <FastField
            label='SELECT TIME (EST)'
            component={DateTimePicker}
            disabled={!!readOnly}
            full
            onlyTime
            name='sendingStartsAt'
            value={new Date(values?.sendingStartsAt!)}
            error={touched?.sendingStartsAt && errors?.sendingStartsAt ? (errors?.sendingStartsAt as string) : ''}
            onChange={(newValue: Date) => {
              setFieldTouched!('sendingStartsAt', true);
              setFieldValue!('sendingStartsAt', newValue);
            }}
          />
        </div>

        <div className='min-w-[1rem]'>
        </div>

        <div className='flex-grow min-w-[calc(33%-1rem)] max-w-[calc(33%-1rem)] select-none'>
          <h5 className='flex mb-5 uppercase text-medium-gray'>
            <span>COOL DOWN PERIOD</span>
            <Info
              className="items-center ml-2"
              text="Prevent message fatigue with the Cool Down Period. After receiving a message, recipients are automatically added to a cool down group to ensure they won't be contacted again until the period has passed."
            />
          </h5>
          <div className='flex'>
            <div className='mr-4 max-w-20'>
              <FastField
                component={TextInput}
                type='number'
                id="cooldownPeriodCount"
                name="cooldownPeriodCount"
                disabled={true}
                value={1}
                error={touched?.cooldownPeriodCount && errors?.cooldownPeriodCount ? errors.cooldownPeriodCount : ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className='w-full'>
              <Autocomplete
                readOnly={true}
                disabled={true}
                value={cooldownOptions.find((option) => option.value === RecurringCampaignCooldownPeriodEnum.MONTHS)}

                options={cooldownOptions}
                onChange={(dropdownValue: IDropdownValue | undefined) => {
                  setFieldTouched!('cooldownPeriod', true);
                  setFieldValue!('cooldownPeriod', dropdownValue?.value);
                }}
                onBlur={(dropdownValue: IDropdownValue) => {
                  setFieldTouched!('cooldownPeriod', true);
                  setFieldValue!('cooldownPeriod', dropdownValue.value);
                }}
                showError={!!errors?.cooldownPeriod}
                error={touched?.cooldownPeriod ? errors?.cooldownPeriod : ''}
              />
            </div>
          </div>
        </div>
      </div>

      <h4 className='mb-6 text-dark-gray'>
        2. Select Duration: When would you like to start your campaign?
      </h4>

      <div className='flex flex-row w-full recurring-dates'>
        <div className='flex-grow min-w-[calc(50%-1rem)] max-w-[calc(50%-1rem)]'>
          <FastField
            label='START DATE'
            component={DateTimePicker}
            disabled={!!readOnly}
            onlyDate
            name='startsAt'
            value={new Date(values?.startsAt!)}
            error={touched?.startsAt && errors?.startsAt ? (errors?.startsAt as string) : ''}
            onChange={(newValue: Date) => {
              setFieldTouched!('startsAt', true);
              setFieldValue!('startsAt', newValue);
            }}
          />
        </div>

        <div className='min-w-[1rem]'>
        </div>

        <div className='relative flex-grow min-w-[calc(50%-1rem)] max-w-[calc(50%-1rem)]'>
          <div className='absolute right-0 z-10 select-none'>
            <Checkbox
              id="hasNoEndDate"
              name="hasNoEndDate"
              label="No End Date"
              checked={!values?.hasNoEndDate}
              readOnly={!!readOnly}
              disabled={!!readOnly}
              onChange={() => {
                setFieldTouched!('hasNoEndDate', true);
                setFieldValue!('hasNoEndDate', !values?.hasNoEndDate);
              }}
            />
          </div>

          {values?.hasNoEndDate && (
            <FastField
              label='END DATE'
              component={DateTimePicker}
              onlyDate
              name='endsAt'
              disabled={!!readOnly || !values?.hasNoEndDate}
              value={!!values?.hasNoEndDate ? new Date(values?.endsAt!) : undefined}
              error={touched?.endsAt && errors?.endsAt ? (errors?.endsAt as string) : ''}
              onChange={(newValue: Date) => {
                setFieldTouched!('endsAt', true);
                setFieldValue!('endsAt', newValue);
              }}
            />
          )}

          {!values?.hasNoEndDate && (
            <FastField
              style={{ pointerEvents: 'none' }}
              component={TextInput}
              label='END DATE'
              disabled={true}
              value={'N/A'}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default BuildScheduleMessage;
