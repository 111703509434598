import { MessageTypeEnum, RecurringCampaignFrequencyEnum, RecurringCampaignStatusEnum } from '@shared/enums';
import { ICustomDataPointEntity } from '@shared/models';
import { checkSHAFTCompliance, getCurrentDate, getSMSMessageSegmentCount } from '@shared/services';
import { IDropdownValue } from '@Wonder-Cave/ui/dist/Autocomplete';
import { FormikErrors, FormikTouched } from 'formik';
import { RuleGroupTypeIC } from 'react-querybuilder';
import * as yup from 'yup';
import { CampaignTypeEnum } from '../../../shared/recurringcampaigns/campaign-type-enum';
import { IRecurringCampaignsPreviewForm } from '../../types';

export interface IPreviewProps {
  values?: IRecurringCampaignForm;
  errors?: FormikErrors<IRecurringCampaignForm>;
  touched?: FormikTouched<IRecurringCampaignForm>;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldTouched?: (field: string, touched: boolean) => void;
  setFieldError?: (field: string, msg: string | undefined) => void;
  handleChange?: () => void;
  handleBlur?: () => void;
  setLeads: (leads: any) => void;
  setLoading: (value: boolean) => void;
  saveDraft: (formikProps: any, exit: boolean) => Promise<any>;
  setSending: (value: boolean) => void;
  saveLoading: boolean;
  leads: number;
  submissionError?: string;
  sending: boolean;
  // domains: IDomainsEntity[];
  customDataPoints: ICustomDataPointEntity[];
}

export interface IRecurringCampaignForm extends IRecurringCampaignsPreviewForm {
  id?: string; // Only populates after a save

  // Misc.
  status: RecurringCampaignStatusEnum;
  sendTestMessages?: boolean;
  campaignType: CampaignTypeEnum;

  // Plan
  isMMSVideo?: boolean;
  mediaUrl?: string;
  showSuppressions: boolean,
  name: string;
  client?: IDropdownValue;
  messageType?: MessageTypeEnum;
  externalId?: string;
  mediaFile?: any;
  mediaName?: string;
  audiences: IDropdownValue[];
  suppressions: IDropdownValue[];
  frontendInlineSuppression: RuleGroupTypeIC;
}

export const recurringCampaignPreviewFormSchema: yup.SchemaOf<IRecurringCampaignsPreviewForm> = yup.object().shape({
  message: yup
    .string()
    .test('notContainsPipe', 'Message must not contain "|" symbol', (message) => !(message && message.includes('|')))
    .when('messageType', {
      is: (messageType: MessageTypeEnum) => messageType === MessageTypeEnum.MMS,
      then: yup.string().max(1600, 'Message cannot be longer than 1600 characters'),
      otherwise: yup.string().max(1000, 'Message cannot be longer than 1000 characters'),
    })
    .test('containsLink', 'Message must contain {{link}}', (message: string | undefined, context): boolean => {
      return context.parent.clickTrackingEnabled && message ? message?.toLowerCase()?.includes('{{link}}') : true;
    })
    .test('notContainsLink', 'Message must not contain {{link}}', (message: string | undefined, context): boolean => {
      return !context.parent.clickTrackingEnabled && message ? !message?.toLowerCase()?.includes('{{link}}') : true;
    })
    .test('shaftCompliance', 'Please update your message to remove banned words.', (message: string | undefined, context): boolean => {
      return checkSHAFTCompliance(message);
    })
    .test('segmentCount', 'Segment count must not be larger than 10', (message: string | undefined, context): boolean => {
      if (context.parent.messageType === MessageTypeEnum.SMS) {
        return getSMSMessageSegmentCount(message ?? '') <= 10;
      } else {
        return true;
      }
    })
    .defined('Required'),

  clickTrackingEnabled: yup.boolean(),

  url: yup
    .string()
    .when('clickTrackingEnabled', {
      is: (clickTrackingEnabled: boolean) => clickTrackingEnabled,
      then: yup.string().test('apostrophe', 'URL cannot have an apostrophe', (value) => !value?.includes('\'')).url('Invalid URL format').defined('Required'),
    }),

  domainId: yup
    .string()
    .when('clickTrackingEnabled', {
      is: (clickTrackingEnabled: boolean) => clickTrackingEnabled,
      then: yup.string().defined('Required'),
    }),
  domain: yup
    .string()
    .when('clickTrackingEnabled', {
      is: (clickTrackingEnabled: boolean) => clickTrackingEnabled,
      then: yup.string().defined('Required'),
    }),

  hasNoEndDate: yup.boolean(),

  startsAt: yup
    .date()
    .min(getCurrentDate({ clearTime: true }), 'Campaign cannot start in the past')
    .typeError('Invalid Date')
    .defined('Required'),

  endsAt: yup
    .date()
    .when('hasNoEndDate', {
      is: (hasNoEndDate: boolean) => hasNoEndDate,
      then: yup.date().min(yup.ref('startsAt'), 'End date cannot be before Start date').typeError('Invalid Date').defined('Required'),
    }),

  sendingStartsAt: yup
    .date()
    .typeError('Invalid Date')
    .defined('Required'),

  frequency: yup
    .mixed<RecurringCampaignFrequencyEnum>().oneOf([RecurringCampaignFrequencyEnum.DAILY])
    .defined('Required')
    .required('Required'),
});