import { useAuth0 } from '@auth0/auth0-react';
import { Auth0Role } from '@shared/models';
import { configure } from 'axios-hooks';
import { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AppWrapper from './AppWrapper';
import { TokenResponse, configureAxiosWithAuth0 } from './authAxios';
import Users from './components/AdminSettings/Users';
import { Audiences } from './components/Audiences/Audiences';
import AllCampaigns from './components/Campaigns/AllCampaigns';
import Campaigns from './components/Campaigns/Campaigns';
import { Clients } from './components/Clients/Clients';
import { Contacts } from './components/Contacts/Contacts';
import { CustomDataPoints } from './components/CustomDataPoints/CustomDataPoints';
import HealthCheckNumbers from './components/HealthCheckNumbers/HealthCheckNumbers';
import JourneyDetails from './components/Journeys/JourneyDetails';
import Journeys from './components/Journeys/Journeys';
import Login from './components/Login';
import RecurringCampaigns from './components/RecurringCampaigns/RecurringCampaigns';
import RecurringCampaignsCreate from './components/RecurringCampaigns/RecurringCampaignsCreate';
import RecurringCampaignsDetails from './components/RecurringCampaigns/RecurringCampaignsDetails';
import { Suppressions } from './components/Suppressions/Suppressions';
import SystemNumbers from './components/SystemNumbers/SystemNumbers';
import TCRCampaigns from './components/TCRCampaigns/TCRCampaigns';
import { PageNotFound } from './components/shared/Auth/PageNotFound';
import { ProtectedRoute } from './components/shared/Auth/ProtectedRoute';
import { initializeWebSocket } from './contexts/SocketContext';
import { auth0Audience, auth0Scope } from './providers/auth0.provider';

const App = (): JSX.Element => {
  const [isConfigured, setIsConfigured] = useState(false);
  const {
    isAuthenticated: isLoggedIn,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    logout: auth0Logout,
    user: auth0User,
  } = useAuth0();

  initializeWebSocket();

  async function getAccessToken() {
    const response = await getAccessTokenSilently({
      detailedResponse: true,
      audience: auth0Audience,
      ignoreCache: false,
      scope: auth0Scope,
    });

    const token: TokenResponse = {
      id_token: response.id_token,
      access_token: response.access_token,
      expires_in: response.expires_in,
      scope: response.scope,
    };

    await configureAxios(token);
  }

  async function authenticateWithConsent() {
    const authToken = await getAccessTokenWithPopup({ audience: auth0Audience, ignoreCache: true, scope: auth0Scope });

    const response: TokenResponse = {
      access_token: authToken,
      expires_in: 1000000,
    };

    await configureAxios(response);
  }

  async function configureAxios(token: TokenResponse) {
    const authAxios = configureAxiosWithAuth0({ token, auth0User, auth0Logout });

    configure({
      axios: authAxios,
      defaultOptions: { useCache: false },
    });

    setIsConfigured(true);
  }
  useEffect(() => {
    const auth = async () => {
      try {
        await getAccessToken();
      } catch (e) {
        console.error(e);
      }
    };
    if (isLoggedIn) {
      auth();
    }
  }, [isLoggedIn]);
  return (
    <Routes>
      <Route
        path="/login"
        element={
          <Login
            isConfigured={isConfigured}
            authenticate={getAccessToken}
            authenticateWithConsent={authenticateWithConsent}
          />
        }
      />
      <Route element={<AppWrapper configured={isConfigured} />}>
        <Route element={<Navigate to="/campaigns" />} path="/" />
        <Route element={<Campaigns />} path="/campaigns" />
        <Route element={<RecurringCampaigns />} path="/recurring-campaigns" />
        <Route element={<RecurringCampaignsCreate />} path="recurring-campaigns/:id/edit" />
        <Route element={<RecurringCampaignsDetails />} path="/recurring-campaigns/:id" />
        <Route element={<RecurringCampaignsCreate />} path="/recurring-campaigns/create" />
        <Route element={<ProtectedRoute roles={[Auth0Role.A2P_SUPER_ADMIN]} />}>
          <Route element={<Journeys />} path="/journeys" />
          <Route element={<JourneyDetails />} path="/journeys/:id" />
        </Route>
        <Route element={<Contacts />} path="/contacts" />
        <Route element={<Audiences />} path="/audiences" />
        <Route element={<Suppressions />} path="/suppressions" />
        <Route element={<CustomDataPoints />} path="/custom-data-points" />
        <Route element={<Clients />} path="/clients" />
        <Route element={<HealthCheckNumbers />} path="/test-numbers" />
        <Route element={<ProtectedRoute roles={[Auth0Role.A2P_ADMIN, Auth0Role.A2P_SUPER_ADMIN]} />}>
          <Route element={<AllCampaigns />} path="/all-campaigns" />
          <Route element={<SystemNumbers />} path="/system-numbers" />
          <Route element={<ProtectedRoute roles={[Auth0Role.A2P_ADMIN]} />}>
            <Route element={<TCRCampaigns />} path="/tcr-campaigns" />
            <Route element={<Users />} path="/users" />
          </Route>
        </Route>
      </Route>
      <Route element={PageNotFound} />
    </Routes >
  );
};

export default App;
