import { ICustomDataPointEntity } from '@shared/models';
import { LoadingIndicator } from '@Wonder-Cave/ui';
import { IRecurringCampaignForm } from '../../RecurringCampaigns/types';
import PreviewMedia from '../Media/PreviewMedia';
// import { ExternalV1CampaignDetailsResponse, ICampaignDetails } from '@gr/shared/models';
// import PreviewMedia from '../shared/Media/PreviewMedia';

interface IMessagePreviewProps {
  campaign?: Partial<IRecurringCampaignForm>; //Partial<ExternalV1CampaignDetailsResponse>;
  domain?: string;
  customDataPoints: ICustomDataPointEntity[];
}



const MessagePreview = ({ campaign, domain, customDataPoints }: IMessagePreviewProps) => {

  const replaceDefaultDataPoints = (previewMessage) => {
    const regex = /\{\{.*?\}\}/g;
    const matches = previewMessage.match(regex) ?? [];

    matches.forEach((match) => {
      const cleanMatch = match.replaceAll("\{", "").replaceAll("\}", "");
      const dataPoint = customDataPoints.find((element) => element.name === cleanMatch);
      previewMessage = previewMessage.replace(`${match}`, dataPoint?.defaultValue);
    });
    return previewMessage;
  };


  return (
    <div className="p-12 ml-8 shadow-inner rounded-4xl bg-ice">
      <div
        className="pt-24 pl-4"
        style={{
          backgroundImage: `url(phone.svg)`,
          backgroundSize: '100% 100%',
          width: '299px',
          height: '592.949px',
        }}
      >
        <div className="pt-8 w-[270px] h-[391px] bg-white overflow-scroll">
          {!!campaign && !!campaign.mediaUrl && (
            <div className='ml-6 w-[75.3%] mb-1'>
              <PreviewMedia isMMSVideo={campaign.isMMSVideo} mediaUrl={campaign.mediaUrl} />
            </div>
          )}
          {!!campaign ? (
            campaign?.clickTrackingEnabled ? (
              <div className={`detail-bubble`}><p className='text-xs'>{replaceDefaultDataPoints(campaign.message?.replace(/{{link}}/gi, domain + '/az2bx1').replace(/\|/g, ' '))}</p></div>
            ) :
              (<div className={`detail-bubble`}><p className='text-xs'>{replaceDefaultDataPoints(campaign.message?.replace(/\|/g, ' '))}</p></div>
              )) : (
            <div className={`detail-bubble`}>
              <LoadingIndicator />
            </div>)
          }

        </div>
      </div>
    </div>
  );
};

export default MessagePreview;